import React from 'react';
import { Banner, BannerArea, Content } from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import coloricon from '../../styles/coloricon';

export default function Services() {
  const { IconCheckCircle: IconCheck } = coloricon;
  return (
    <>
      <Header />
      <Banner>
        <BannerArea>
          <Content>
            <h1>Serviços</h1>

            <p>
              <IconCheck />
              Atendimento de sinistros em todas as fases e ações necessárias
              junto aos órgãos competentes.
            </p>

            <p>
              <IconCheck />
              Análise de riscos e avaliação de danos de mercadorias.
            </p>
            <p>
              <IconCheck />
              Negociação de acordos e resolução de dispustas junto aos parceiros
              envolvidos.
            </p>

            <p>
              <IconCheck />
              Análise e regularização de documentação.
            </p>

            <p>
              <IconCheck />
              Fornecimento de parecer técnico e informações para tomada de
              decisão
            </p>

            <p>
              <IconCheck />
              Sistema próprio para consulta e armazenamento de dados aberto para
              customizações.
            </p>
          </Content>
        </BannerArea>
      </Banner>

      <Footer />
    </>
  );
}
