import React, { useRef, useCallback, useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import { useNavigate } from 'react-router-dom';
import DateInput from '../../components/DatePickerInput';
import Button from '../../components/Button';
import Input from '../../components/Input';
import coloricon from '../../styles/coloricon';
import api from '../../services/api';
import { Container, Content, GroupDate } from './styles';

export default function CreateSinisters() {
  const { IconDocument } = coloricon;
  const formRef = useRef(null);
  const navigation = useNavigate();

  const [sinister_date, setSinister_date] = useState(new Date());

  const handleSubmit = useCallback(
    async (data) => {
      try {
        const schemaValidation = Yup.object().shape({
          sinister_number: Yup.string().required(
            'O número do processo é obrigatório!'
          ),

          sinister_type: Yup.string().required(
            'O tipo do sinistro é obrigatório!'
          ),

          sinister_event_type: Yup.string().required(
            'O tipo de evento do sinistro é obrigatório!'
          ),

          sinister_cause: Yup.string().required('A causa do sinistro'),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        formRef.current.setErrors({});

        await api.post('/sinisters', data);

        toast.info('O sinistro foi criada com sucesso!');

        navigation({ pathname: `/sinisterManager` });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};
          err.inner.forEach((error) => {
            errorMessages[error.path] = error.message;
          });

          formRef.current.setErrors(errorMessages);
        } else {
          toast.info(err.response.data.error);
        }
      }
    },
    [navigation]
  );

  return (
    <Container>
      <Content>
        <h1>Adicionar sinistro</h1>

        <Form onSubmit={handleSubmit} ref={formRef}>
          <Input
            name="sinister_number"
            type="text"
            icon={IconDocument}
            placeholder="Número do processo"
            required
          />

          <GroupDate>
            <h3>Data:</h3>
            <DateInput
              name="sinister_date"
              selected={sinister_date}
              onChange={(date) => setSinister_date(date)}
            />
          </GroupDate>

          <Input
            name="sinister_type"
            type="text"
            icon={IconDocument}
            placeholder="Tipo"
            required
          />
          <Input
            name="sinister_event_type"
            type="text"
            icon={IconDocument}
            placeholder="Evento"
            required
          />
          <Input
            name="sinister_cause"
            type="text"
            icon={IconDocument}
            placeholder="Causa"
            required
          />

          <Button type="submit" style={{ width: '100%', marginBottom: 15 }}>
            Salvar
          </Button>
        </Form>
      </Content>
    </Container>
  );
}
