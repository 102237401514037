import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '../../components/BreadcrumbsMUI';
import { Container, Content } from './styles';

// abas separadas
import SinisterInput from './sinisterInput';
import InsuranceInput from './insuranceInput';
import BrokenInput from './brokerInput';
import InsuredInput from './insuredInput';
import OccurrenceInput from './occurrenceInput';
import DetailInput from './detailInput';

export default function UpdateSinisters() {
  const { idSinister } = useParams();
  const [value, setValue] = useState('1');

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const useStyles = makeStyles({
    root: {
      '& .MuiButtonBase-root': {
        fontFamily: 'Roboto Slab , serif',
      },
    },
  });

  const classes = useStyles();

  return (
    <>
      <Breadcrumbs
        nameOfCurrentPage="Editar sinistros"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/sinisterManager',
          },
        ]}
      />
      <Container>
        <Content>
          <h1>Editar sinistro</h1>

          {/* implantacao das tabs */}

          <Box
            sx={{
              width: '100%',
              typography: 'body1',
              fontFamily: 'inherit',
            }}
            className={classes.root}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChangeTabs}
                  aria-label="Informações do sinistro"
                  textColor="inherit"
                  indicatorColor="primary"
                  variant="fullWidth"
                  fontFamily="inherit"
                >
                  <Tab label="Sinistro" value="1" />
                  <Tab label="Seguradora" value="2" />
                  <Tab label="Corretora" value="3" />
                  <Tab label="Segurado" value="4" />
                  <Tab label="Ocorrência" value="5" />
                  <Tab label="Detalhes" value="6" />
                </TabList>
              </Box>

              <TabPanel value="1">
                <SinisterInput idSinister={idSinister} />
              </TabPanel>
              <TabPanel value="2">
                <InsuranceInput idSinister={idSinister} />
              </TabPanel>
              <TabPanel value="3">
                <BrokenInput idSinister={idSinister} />
              </TabPanel>
              <TabPanel value="4">
                <InsuredInput idSinister={idSinister} />
              </TabPanel>
              <TabPanel value="5">
                <OccurrenceInput idSinister={idSinister} />
              </TabPanel>
              <TabPanel value="6">
                <DetailInput idSinister={idSinister} />
              </TabPanel>
            </TabContext>
          </Box>
        </Content>
      </Container>
    </>
  );
}
