import styled from 'styled-components';
import banner from '../../assets/banner.jpg';
import coloricon from '../../styles/coloricon';

const { fontColor, secondaryColor } = coloricon;

const imageChoosed = banner;

export const Banner = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${imageChoosed});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: calc(100vh - 80px - 100px);
`;

export const BannerArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 100px - 80px);
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  align-items: center;
  text-align: center;
  padding: 0 10px;

  @media (max-width: 768px) {
    font-size: 15px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }

  > h2 {
    margin-bottom: 20px;
  }

  > form {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${fontColor};
    transition: all 0.3s ease-in-out;
    font-size: 1.2em;
    margin-bottom: 25px;

    svg {
      margin-right: 10px;
    }

    &:hover {
      color: ${secondaryColor};
    }
  }
`;

export const LogoArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;

  @media (max-width: 425px) {
    flex-direction: column;

    > img {
      margin-bottom: 10px;
    }
  }

  > img {
    width: 2.25em;
    height: 2.25em;
  }

  > h1 {
    font-size: 2em;
    margin-left: 5px;
  }
`;
