import React from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import coloricon from '../styles/coloricon';
import Button from '../components/Button';

export default function ExportCSV({ csvData, fileName }) {
  const { IconDownload, fontColor } = coloricon;

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const fileExtension = '.xlsx';

  const exportToCSV = (csvDataChild, fileNameChild) => {
    const ws = XLSX.utils.json_to_sheet(csvDataChild);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileNameChild + fileExtension);
  };

  return (
    <Button type="button" onClick={() => exportToCSV(csvData, fileName)}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <IconDownload size={20} color={fontColor} />
        <p style={{ paddingLeft: '7px', color: fontColor }}>Baixar planilha</p>
      </div>
    </Button>
  );
}

ExportCSV.propTypes = {
  csvData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.element,
  ]).isRequired,
  fileName: PropTypes.string.isRequired,
};
