import * as Yup from 'yup';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { unMask, mask } from 'remask';
import { Form } from '@unform/web';
import Input from '../../components/Input';
import Loading from '../../components/Loading';
import Button from '../../components/Button';
import DateInput from '../../components/DatePickerInput';
import RadioInput from '../../components/RadioInput';
import SelectInput from '../../components/SelectInput';
import coloricon from '../../styles/coloricon';
import { GroupDate, OccurrenceTitle, Container, Content } from './styles';
import api from '../../services/api';

export default function OccurrenceInput({ idSinister }) {
  const [loading, setLoading] = useState(true);
  const [occurrence, setOccurrence] = useState(null);
  const formRefOccurrence = useRef(null);
  const {
    IconUser,
    IconMap,
    IconDocument,
    IconMail,
    IconTruck,
    IconLocation,
    IconPackage,
  } = coloricon;
  const [occurrence_date, setOccurrence_date] = useState(new Date());
  const [conductor_birth_date, set_conductor_birth_date] = useState(new Date());
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [conductor_cpf_cnpj, setConductor_cpf_cnpj] = useState('');
  const [conductor_cnh_expiration, setConductor_cnh_expiration] = useState(
    new Date()
  );
  const [from_date, setFrom_date] = useState(new Date());
  const [from_cnpj, set_from_cnpj] = useState('');

  const [to_date, setTo_date] = useState(new Date());
  const [to_cnpj, set_to_cnpj] = useState('');

  const [masked_from_zipcode, setMasked_from_zipcode] = useState('');
  const [masked_to_zipcode, setMasked_to_zipcode] = useState('');
  const [masked_occurrence_zipcode, setMasked_occurrence_zipcode] =
    useState('');

  const [optionsConductorSex, setOptionsConductorSex] = useState([
    { id: 1, label: 'Masculino', value: 'masculino', selected: false },
    { id: 2, label: 'Feminino', value: 'feminino', selected: false },
    { id: 3, label: 'Outros', value: 'outros', selected: false },
  ]);

  const setFilteredCities = useCallback(async (event) => {
    const stateObj = event;
    const response = await api.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${
        stateObj && stateObj.value
      }/distritos`
    );
    const arrayCities = response.data;
    const finalArray = arrayCities
      .map((item) => {
        const result = {
          id: item.id,
          value: item.nome,
          label: item.nome,
        };
        return result;
      })
      .sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

    setCityList(finalArray);
  }, []);

  const onChangeConductor_cpf_cnpj = useCallback((event) => {
    const originalValue = unMask(event.target.value);
    const masked = mask(originalValue, [
      '999.999.999-99',
      '99.999.999/9999-99',
    ]);
    setConductor_cpf_cnpj(masked);
  }, []);

  const onChangeFromCnpj = useCallback((event) => {
    const originalValue = unMask(event.target.value);
    const masked = mask(originalValue, ['99.999.999/9999-99']);
    set_from_cnpj(masked);
  }, []);

  const onChangeToCnpj = useCallback((event) => {
    const originalValue = unMask(event.target.value);
    const masked = mask(originalValue, ['99.999.999/9999-99']);
    set_from_cnpj(masked);
  }, []);

  // preencher o endereço automatico
  const autoCompleteAddress = useCallback(async (event, referenceField) => {
    const { value } = event.target;
    const originalValue = unMask(value);
    const masked = mask(originalValue, ['99999-999']);

    if (referenceField === 'from_zipcode') {
      setMasked_from_zipcode(masked);

      if (value && value.length === 9) {
        const response = await fetch(
          `https://viacep.com.br/ws/${value}/json`
        ).then((result) => result.json());

        if (response) {
          formRefOccurrence.current.setData({
            from_address: response.logradouro ? response.logradouro : null,
            from_complement: response.complemento ? response.complemento : null,
            from_district: response.bairro ? response.bairro : null,
            from_city: response.localidade ? response.localidade : null,
            from_state: response.uf ? response.uf : null,
            from_number: null,
          });
        }
      }
    }
    if (referenceField === 'to_zipcode') {
      setMasked_to_zipcode(masked);
      if (value && value.length === 9) {
        const response = await fetch(
          `https://viacep.com.br/ws/${value}/json`
        ).then((result) => result.json());

        if (response) {
          formRefOccurrence.current.setData({
            to_address: response.logradouro ? response.logradouro : null,
            to_complement: response.complemento ? response.complemento : null,
            to_district: response.bairro ? response.bairro : null,
            to_city: response.localidade ? response.localidade : null,
            to_state: response.uf ? response.uf : null,
            to_number: null,
          });
        }
      }
    }
    if (referenceField === 'occurrence_zipcode') {
      setMasked_occurrence_zipcode(masked);

      if (value && value.length === 9) {
        const response = await fetch(
          `https://viacep.com.br/ws/${value}/json`
        ).then((result) => result.json());

        if (response) {
          formRefOccurrence.current.setData({
            occurrence: {
              occurrence_address: response.logradouro
                ? response.logradouro
                : null,
              occurrence_complement: response.complemento
                ? response.complemento
                : null,
              occurrence_district: response.bairro ? response.bairro : null,
              occurrence_city: response.localidade ? response.localidade : null,
              occurrence_state: response.uf ? response.uf : null,
              occurrence_number: null,
            },
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    async function loaderItems() {
      try {
        const response = await api.get(`/sinisters/${idSinister}`);

        //! setando dados do condutor: sexo, nascimento, cpf , validade CNH caso já exista
        if (response.data.occurrence.conductor_sex) {
          const index = optionsConductorSex.findIndex(
            (item) => item.value === response.data.occurrence.conductor_sex
          );
          if (index > -1) {
            optionsConductorSex[index].selected === true;
            setOptionsConductorSex(optionsConductorSex);
          }
        }

        set_conductor_birth_date(
          response.data.occurrence.conductor_birthdate
            ? parseISO(response.data.occurrence.conductor_birthdate)
            : new Date()
        );

        setConductor_cpf_cnpj(
          response.data.occurrence.conductor_cpf_cnpj
            ? response.data.occurrence.conductor_cpf_cnpj
            : ''
        );

        setConductor_cnh_expiration(
          response.data.occurrence.conductor_cnh_expiration
            ? parseISO(response.data.occurrence.conductor_cnh_expiration)
            : new Date()
        );

        //! dados da origem - from
        setFrom_date(
          response.data.occurrence.from_date
            ? parseISO(response.data.occurrence.from_date)
            : new Date()
        );

        set_from_cnpj(
          response.data.occurrence.from_cnpj
            ? response.data.occurrence.from_cnpj
            : ''
        );

        //! dados de destino - to
        setTo_date(
          response.data.occurrence.to_date
            ? parseISO(response.data.occurrence.to_date)
            : new Date()
        );

        set_to_cnpj(
          response.data.occurrence.to_cnpj
            ? response.data.occurrence.to_cnpj
            : ''
        );

        //! occurrence date
        setOccurrence_date(
          response.data.occurrence.occurrence_date
            ? parseISO(response.data.occurrence.occurrence_date)
            : new Date()
        );

        // setando lista de estados
        const responseIBGE = await api.get(
          'https://servicodados.ibge.gov.br/api/v1/localidades/estados'
        );
        const arrayState = responseIBGE.data;
        const finalArray = arrayState
          .map((item) => {
            const result = {
              id: item.id,
              value: item.sigla,
              label: item.nome,
            };
            return result;
          })
          .sort((a, b) => {
            if (a.label > b.label) {
              return 1;
            }
            if (a.label < b.label) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
        setStateList(finalArray);
        setOccurrence(response.data.occurrence);

        if (
          response.data.occurrence &&
          response.data.occurrence.conductor_sex
        ) {
          const index = optionsConductorSex.findIndex(
            (item) => item.value === response.data.occurrence.conductor_sex
          );
          if (index > -1) {
            optionsConductorSex[index].selected === true;
          }
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(
          error?.response.data.error || 'Falha no carregamento das informações!'
        );
      }
    }
    loaderItems();
  }, [idSinister, optionsConductorSex]);

  const handleSubmitOccurrence = useCallback(
    async (data) => {
      try {
        const schemaValidation = Yup.object().shape({
          // conductor

          conductor_sex: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O gênero é obrigatório!'),
            otherwise: Yup.string(),
          }),

          conductor_name: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O nome é obrigatório!'),
            otherwise: Yup.string(),
          }),

          conductor_birthdate_formatted: Yup.date().required(
            'O data é obrigatória!'
          ),

          conductor_city: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('A cidade é obrigatória!'),
            otherwise: Yup.string(),
          }),

          conductor_state: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O estado é obrigatório!'),
            otherwise: Yup.string(),
          }),

          conductor_cpf_cnpj_formatted: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required('O CPF/CNPJ é obrigatório!')
              .min(14, 'O CPF/CNPJ precisa mínimo 14 dígitos!')
              .max(18, 'O CPF/CNPJ suporta máximo 18 dígitos!'),
            otherwise: Yup.string(),
          }),

          conductor_rg: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O RG é obrigatório!'),
            otherwise: Yup.string(),
          }),

          conductor_mail: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .email('O formato de e-mail é inválido!')
              .required('O e-mail é obrigatório!'),
            otherwise: Yup.string(),
          }),

          conductor_phone_formatted: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required('O telefone é obrigatório!')
              .min(14, 'O telefone precisa mínimo 10 dígitos!')
              .max(15, 'O telefone suporta máximo 11 dígitos!'),
            otherwise: Yup.string(),
          }),

          conductor_cnh: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O número da CNH é obrigatório!'),
            otherwise: Yup.string(),
          }),

          conductor_cnh_category: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('A categoria é obrigatória!'),
            otherwise: Yup.string(),
          }),

          conductor_cnh_expiration_formatted: Yup.date().required(
            'A data de expiração é obrigatória'
          ),

          vehicle_model: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O modelo é obrigatório!'),
            otherwise: Yup.string(),
          }),

          vehcile_plate_horse: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('A placa do cavalo é obrigatório!'),
            otherwise: Yup.string(),
          }),

          vehicle_plate_truck: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('A placa do caminhão é obrigatório!'),
            otherwise: Yup.string(),
          }),

          vehicle_color: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('A cor é obrigatória!'),
            otherwise: Yup.string(),
          }),

          vehicle_capacity: Yup.number().when({
            is: (val) => val && val.length > 0,
            then: Yup.number().required('A capacidade é obrigatória!'),
            otherwise: Yup.number().nullable(true),
          }),

          from_date_formatted: Yup.date().required(
            'A data da origem é obrigatória!'
          ),

          from_address: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O endereço é obrigatório!'),
            otherwise: Yup.string(),
          }),

          from_number: Yup.number().when({
            is: (val) => val && val.length > 0,
            then: Yup.number().required('O número é obrigatório!'),
            otherwise: Yup.number().nullable(true),
          }),

          from_district: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O bairro é obrigatório!'),
            otherwise: Yup.string(),
          }),

          from_complement: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O complemento é obrigatório!'),
            otherwise: Yup.string(),
          }),

          from_city: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('A cidade é obrigatório!'),
            otherwise: Yup.string(),
          }),

          from_state: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O estado é obrigatório!'),
            otherwise: Yup.string(),
          }),

          from_zipcode_formatted: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required('O cep é obrigatório!')
              .min(9, 'O cep precisa mínimo 8 dígitos!')
              .max(9, 'O cep suporta máximo 8 dígitos!'),
            otherwise: Yup.string(),
          }),

          from_cnpj_formatted: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required('O CNPJ é obrigatório!')
              .min(18, 'O CNPJ precisa mínimo 18 dígitos!')
              .max(18, 'O CNPJ suporta máximo 18 dígitos!'),
            otherwise: Yup.string(),
          }),

          to_date_formatted: Yup.date().required(
            'A data de destino é obrigatória!'
          ),

          to_address: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O endereço é obrigatório!'),
            otherwise: Yup.string(),
          }),

          to_number: Yup.number().when({
            is: (val) => val && val.length > 0,
            then: Yup.number().required('O número é obrigatório!'),
            otherwise: Yup.number().nullable(true),
          }),

          to_district: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O bairro é obrigatório!'),
            otherwise: Yup.string(),
          }),

          to_complement: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O complemento é obrigatório!'),
            otherwise: Yup.string(),
          }),

          to_city: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('A cidade é obrigatória!'),
            otherwise: Yup.string(),
          }),

          to_state: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O estado é obrigatório!'),
            otherwise: Yup.string(),
          }),

          to_zipcode_formatted: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required('O cep é obrigatório!')
              .min(9, 'O cep precisa mínimo 8 dígitos!')
              .max(9, 'O cep suporta máximo 8 dígitos!'),
            otherwise: Yup.string(),
          }),

          to_cnpj_formatted: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required('O CNPJ é obrigatório!')
              .min(18, 'O CNPJ precisa mínimo 18 dígitos!')
              .max(18, 'O CNPJ suporta máximo 18 dígitos!'),
            otherwise: Yup.string(),
          }),

          occurrence_date_formatted: Yup.date().required(
            'A data da ocorrência é obrigatória!'
          ),

          occurrence_address: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O endereço é obrigatório!'),
            otherwise: Yup.string(),
          }),

          occurrence_number: Yup.number().when({
            is: (val) => val && val.length > 0,
            then: Yup.number().required('O número é obrigatório!'),
            otherwise: Yup.number().nullable(true),
          }),

          occurrence_district: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O bairro é obrigatório!'),
            otherwise: Yup.string(),
          }),

          occurrence_complement: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O complemento é obrigatório!'),
            otherwise: Yup.string(),
          }),

          occurrence_city: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('A cidade é obrigatória!'),
            otherwise: Yup.string(),
          }),

          occurrence_state: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O estado é obrigatório!'),
            otherwise: Yup.string(),
          }),

          occurrence_zipcode_formatted: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required('O cep é obrigatório!')
              .min(9, 'O cep precisa mínimo 8 dígitos!')
              .max(9, 'O cep suporta máximo 8 dígitos!'),
            otherwise: Yup.string(),
          }),

          occurrence_comments: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O comentário é obrigatório!'),
            otherwise: Yup.string(),
          }),
        });

        const dataForSchema = {
          ...data,

          from_number:
            data.from_number !== '' && data.from_number !== null
              ? data.from_number
              : null,

          to_number:
            data.to_number !== '' && data.to_number !== null
              ? data.to_number
              : null,

          occurrence_number:
            data.occurrence_number !== '' && data.occurrence_number !== null
              ? data.occurrence_number
              : null,

          vehicle_capacity:
            data.vehicle_capacity !== '' && data.vehicle_capacity !== null
              ? data.vehicle_capacity
              : null,
        };

        const dataFormatted = {
          ...data,

          from_number:
            data.from_number !== '' && data.from_number !== null
              ? data.from_number
              : null,

          to_number:
            data.to_number !== '' && data.to_number !== null
              ? data.to_number
              : null,

          occurrence_number:
            data.occurrence_number !== '' && data.occurrence_number !== null
              ? data.occurrence_number
              : null,

          vehicle_capacity:
            data.vehicle_capacity !== '' && data.vehicle_capacity !== null
              ? data.vehicle_capacity
              : null,

          conductor_birthdate: data.conductor_birthdate_formatted,

          conductor_cpf_cnpj: data.conductor_cpf_cnpj_formatted
            ? data.conductor_cpf_cnpj_formatted
            : null,

          conductor_phone: data.conductor_phone_formatted
            ? data.conductor_phone_formatted
            : null,

          conductor_cnh_expiration: data.conductor_cnh_expiration_formatted
            ? data.conductor_cnh_expiration_formatted
            : null,

          from_date_formatted: data.from_date_formatted
            ? data.from_date_formatted
            : null,

          from_zipcode: data.from_zipcode_formatted
            ? data.from_zipcode_formatted
            : null,

          from_cnpj: data.from_cnpj_formatted ? data.from_cnpj_formatted : null,

          to_date: data.to_date_formatted ? data.to_date_formatted : null,

          to_zipcode: data.to_zipcode_formatted
            ? data.to_zipcode_formatted
            : null,

          to_cnpj: data.to_cnpj_formatted ? data.to_cnpj_formatted : null,

          occurrence_date: data.occurrence_date_formatted
            ? data.occurrence_date_formatted
            : null,

          occurrence_zipcode: data.occurrence_zipcode_formatted
            ? data.occurrence_zipcode_formatted
            : null,
        };
        await schemaValidation.validate(dataForSchema, {
          abortEarly: false,
        });

        await api.put(`/sinisters/${idSinister}`, {
          occurrenceReceived: dataFormatted,
        });

        toast.info('A ocorrência foi atualizada com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            toast.info(
              `Falha no preenchimento do campo ${error.path}. Mensagem: ${error.message}`
            );
          });
        } else {
          toast.info(
            err?.response.data.error ||
              'Falha no processamento das informações!'
          );
        }
      }
    },
    [idSinister]
  );

  return loading === true ? (
    <Loading />
  ) : (
    <Container>
      <Content>
        <Form
          ref={formRefOccurrence}
          initialData={occurrence}
          onSubmit={handleSubmitOccurrence}
        >
          {/* dados do condutor */}
          <OccurrenceTitle>Dados do condutor</OccurrenceTitle>

          <RadioInput
            name="conductor_sex"
            label="Gênero"
            options={optionsConductorSex}
          />

          <GroupDate>
            <h3>Data nascimento:</h3>
            <DateInput
              name="conductor_birthdate_formatted"
              selected={conductor_birth_date}
              onChange={(date) => setOccurrence_date(date)}
            />
          </GroupDate>

          <Input
            name="conductor_name"
            type="text"
            icon={IconUser}
            placeholder="Nome"
          />

          <SelectInput
            name="conductor_state"
            placeholder="Estado de nascimento"
            icon={IconMap}
            options={stateList}
            onChange={setFilteredCities}
          />

          <SelectInput
            name="conductor_city"
            placeholder="Cidade de nascimento"
            icon={IconMap}
            options={cityList}
          />

          <Input
            type="text"
            name="conductor_cpf_cnpj_formatted"
            placeholder="CNPJ/CPF"
            icon={IconDocument}
            value={conductor_cpf_cnpj}
            onChange={onChangeConductor_cpf_cnpj}
          />

          <Input
            type="text"
            name="conductor_rg"
            placeholder="RG"
            icon={IconDocument}
          />

          <Input
            type="text"
            name="conductor_mail"
            placeholder="Email"
            icon={IconMail}
          />

          <Input
            type="text"
            name="conductor_cnh"
            placeholder="CNH"
            icon={IconDocument}
          />

          <Input
            type="text"
            name="conductor_cnh_category"
            placeholder="Categoria CNH"
            icon={IconDocument}
          />

          <GroupDate>
            <h3>Validade CNH</h3>
            <DateInput
              name="conductor_cnh_expiration_formatted"
              selected={conductor_cnh_expiration}
              onChange={(date) => setConductor_cnh_expiration(date)}
            />
          </GroupDate>

          {/* dados do veiculo */}
          <OccurrenceTitle>Dados do veículo</OccurrenceTitle>
          <Input
            type="text"
            name="vehicle_model"
            placeholder="Modelo"
            icon={IconTruck}
          />
          <Input
            type="number"
            name="vehicle_capacity"
            placeholder="Capacidade"
            icon={IconPackage}
          />

          <Input
            type="text"
            name="vehicle_color"
            placeholder="Cor principal"
            icon={IconDocument}
          />

          <Input
            type="text"
            name="vehicle_plate_truck"
            placeholder="Placa do veículo"
            icon={IconDocument}
          />

          <Input
            type="text"
            name="vehcile_plate_horse"
            placeholder="Placa do cavalo"
            icon={IconDocument}
          />

          {/* origem da partida */}
          <OccurrenceTitle>Dados da origem</OccurrenceTitle>

          <GroupDate>
            <h3>Data partida: </h3>
            <DateInput
              name="from_date_formatted"
              selected={from_date}
              onChange={(date) => setFrom_date(date)}
            />
          </GroupDate>
          <Input
            type="text"
            name="from_cnpj_formatted"
            placeholder="CNPJ"
            icon={IconDocument}
            value={from_cnpj}
            onChange={onChangeFromCnpj}
          />

          <Input
            type="text"
            name="from_zipcode_formatted"
            placeholder="Cep"
            icon={IconLocation}
            inputProps={{ maxLength: 9, size: 9 }}
            value={masked_from_zipcode}
            onChange={(event) => autoCompleteAddress(event, 'from_zipcode')}
          />

          <Input
            type="text"
            name="from_address"
            placeholder="Endereço"
            icon={IconMap}
          />

          <Input
            type="number"
            name="from_number"
            placeholder="Número"
            icon={IconMap}
          />

          <Input
            type="text"
            name="from_district"
            placeholder="Distrito"
            icon={IconMap}
          />

          <Input
            type="text"
            name="from_complement"
            placeholder="Complemento"
            icon={IconMap}
          />

          <Input
            type="text"
            name="from_city"
            placeholder="Cidade"
            icon={IconUser}
          />

          <Input
            type="text"
            name="from_state"
            placeholder="Estado"
            icon={IconUser}
          />

          {/* destino */}
          <OccurrenceTitle>Dados do destino</OccurrenceTitle>
          <GroupDate>
            <h3>Previsão de chegada</h3>
            <DateInput
              name="to_date_formatted"
              selected={to_date}
              onChange={(date) => setTo_date(date)}
            />
          </GroupDate>
          <Input
            type="text"
            name="to_cnpj_formatted"
            placeholder="CNPJ"
            icon={IconDocument}
            value={to_cnpj}
            onChange={onChangeToCnpj}
          />

          <Input
            type="text"
            name="to_zipcode_formatted"
            placeholder="Cep"
            icon={IconLocation}
            value={masked_to_zipcode}
            onChange={(event) => autoCompleteAddress(event, 'to_zipcode')}
          />

          <Input
            type="text"
            name="to_address"
            placeholder="Endereço"
            icon={IconMap}
          />

          <Input
            type="number"
            name="to_number"
            placeholder="Número"
            icon={IconMap}
          />

          <Input
            type="text"
            name="to_district"
            placeholder="Distrito"
            icon={IconMap}
          />

          <Input
            type="text"
            name="to_complement"
            placeholder="Complemento"
            icon={IconMap}
          />

          <Input
            type="text"
            name="to_city"
            placeholder="Cidade"
            icon={IconMap}
          />

          <Input
            type="text"
            name="to_state"
            placeholder="Estado"
            icon={IconMap}
          />

          {/* local da ocorrencia */}
          <OccurrenceTitle>Dados da ocorrência</OccurrenceTitle>

          <GroupDate>
            <h3>Data da ocorrência</h3>
            <DateInput
              name="occurrence_date_formatted"
              selected={occurrence_date}
              onChange={(date) => setOccurrence_date(date)}
            />
          </GroupDate>

          <Input
            type="text"
            name="occurrence_zipcode_formatted"
            placeholder="Cep"
            icon={IconLocation}
            value={masked_occurrence_zipcode}
            onChange={(event) =>
              autoCompleteAddress(event, 'occurrence_zipcode')
            }
          />

          <Input
            type="text"
            name="occurrence_address"
            placeholder="Endereço"
            icon={IconMap}
          />

          <Input
            type="number"
            name="occurrence_number"
            placeholder="Número"
            icon={IconMap}
          />

          <Input
            type="text"
            name="occurrence_district"
            placeholder="Distrito"
            icon={IconMap}
          />

          <Input
            type="text"
            name="occurrence_complement"
            placeholder="Complemento"
            icon={IconMap}
          />

          <Input
            type="text"
            name="occurrence_city"
            placeholder="Cidade"
            icon={IconMap}
          />

          <Input
            type="text"
            name="occurrence_state"
            placeholder="Estado"
            icon={IconMap}
          />

          <Input
            type="text"
            isTextAreaInput
            multiline
            rows={3}
            name="occurrence_comments"
            placeholder="Observações"
            icon={IconDocument}
          />
          <Button type="submit">Salvar</Button>
        </Form>
      </Content>
    </Container>
  );
}
