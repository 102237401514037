import React, { useRef, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import coloricon from '../../styles/coloricon';
import { Banner, Content, BannerArea } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../services/api';
import Header from '../../components/Header';
import BlockUI from '../../components/BlockUi';
import Footer from '../../components/Footer';

export default function ForgotPass() {
  const navigation = useNavigate();
  const { IconMail, IconHome, IconLogin } = coloricon;
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data) => {
      /* validacao */
      try {
        setLoading(true);
        const schemaValidation = Yup.object().shape({
          email: Yup.string()
            .email('Endereço inválido!')
            .required('O e-mail é obrigatório!'),
        });

        formRef.current.setFieldError('email', '');

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        formRef.current.setErrors({});

        const { email } = data;
        const response = await api.post('/forgotPass', { email });
        toast.info(response.data.message);
        setLoading(false);
        navigation({ pathname: '/login' });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};

          err.inner.forEach((error) => {
            errorMessages[error.path] = error.message;
          });

          formRef.current.setErrors(errorMessages);
        } else {
          toast.info(err.response.data.error);
        }
        setLoading(false);
      }
    },
    [navigation]
  );

  return (
    <>
      <Header />
      <Banner>
        {loading && <BlockUI blocking />}

        <BannerArea>
          <Content>
            <h2>Recuperar acesso</h2>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                icon={IconMail}
                name="email"
                type="email"
                placeholder="E-mail"
                required
              />
              <Button type="submit">Enviar</Button>
            </Form>
            <Link to="/login">
              <IconLogin size={22} /> Login
            </Link>
            <Link to="/">
              <IconHome size={22} /> Início
            </Link>
          </Content>
        </BannerArea>
      </Banner>
      <Footer />
    </>
  );
}
