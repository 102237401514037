import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { backgroundHeader, fontColor, secondaryColor, placeholderInputColor } =
  coloricon;

export const Container = styled.div`
  > div {
    margin: 12px 0px;
  }

  input[type='radio']:checked,
  input[type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  input[type='radio']:checked + label,
  input[type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    line-height: 25px;
    display: inline-block;
    color: ${fontColor};
  }

  input[type='radio']:checked + label:before,
  input[type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border: 3px solid ${placeholderInputColor};
    border-radius: 100%;
    background: ${backgroundHeader};
  }

  input[type='radio']:checked + label:after,
  input[type='radio']:not(:checked) + label:after {
    content: '';
    width: 16px;
    height: 16px;
    background: ${secondaryColor};
    position: absolute;
    top: 6.6px;
    left: 7px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  input[type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  input[type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

export const ContainerError = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;
  width: 100%;
  margin-bottom: 5px;

  span {
    display: flex;
    align-items: center;
    color: ${secondaryColor};
    font-size: 14px;
  }

  svg {
    color: ${secondaryColor};
    margin-right: 5px;
  }
`;
