import React from 'react';
import { ToastContainer } from 'react-toastify';
import { BsX } from 'react-icons/bs';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FiAlertCircle } from 'react-icons/fi';
import Routes from './routes';
import GlobalStyle from './styles/global';
import { AuthProvider } from './context/AuthContext';
import coloricon from './styles/coloricon';

const { fontColor, backgroundHeaderHover } = coloricon;

const CloseButton = ({ closeToast }) => (
  <BsX size={22} color={fontColor} onClick={closeToast} />
);

//! definir a mesma fonte para todos os components
const THEME = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Roboto Slab , serif',
      textTransform: 'none',
      fontSize: 16,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={THEME}>
      <AuthProvider>
        <Router>
          <Routes />
        </Router>
      </AuthProvider>
      <GlobalStyle />
      <ToastContainer
        toastStyle={{
          color: fontColor,
          backgroundColor: backgroundHeaderHover,
          borderRadius: '4px',
          // fontSize: '0.9em', manter normal mesmo
          width: 'auto',
        }}
        closeButton={CloseButton}
        autoClose={3000}
        position="top-right"
        hideProgressBar
        icon={<FiAlertCircle color={fontColor} size={20} />}
      />
    </ThemeProvider>
  );
}

export default App;
