import React, { useRef, useContext, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import coloricon from '../../styles/coloricon';
import { AuthContext } from '../../context/AuthContext';
import Header from '../../components/Header';
import { Banner, Content, BannerArea } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Footer from '../../components/Footer';

export default function Login() {
  const navigation = useNavigate();
  const { IconMail, IconLock, IconKey } = coloricon;
  const formRef = useRef(null);
  const { handleLoginUser } = useContext(AuthContext);

  const handleSubmitLogin = useCallback(
    async (data) => {
      try {
        const schemaValidation = Yup.object().shape({
          email: Yup.string()
            .email('Endereço inválido!')
            .required('O e-mail é obrigatório!'),

          password: Yup.string()
            .required('A senha é obrigatória!')
            .min(6, 'A senha precisa mínimo 6 dígitos')
            .max(12, 'A senha suporta máximo 12 dígitos'),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        formRef.current.setErrors({});

        const { email, password } = data;
        const response = await handleLoginUser(email, password);
        if (response) {
          navigation({ pathname: `/dashUser` });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};

          err.inner.forEach((error) => {
            errorMessages[error.path] = error.message;
          });

          formRef.current.setErrors(errorMessages);
        } else {
          toast.info(err.response.data.error);
        }
      }
    },
    [handleLoginUser, navigation]
  );

  return (
    <>
      <Header />
      <Banner>
        <BannerArea>
          <Content>
            <h1>Área de acesso</h1>
            <Form ref={formRef} onSubmit={handleSubmitLogin}>
              <Input
                name="email"
                type="email"
                placeholder="E-mail"
                required
                icon={IconMail}
              />

              <Input
                name="password"
                type="password"
                autoComplete="on"
                placeholder="Senha"
                icon={IconLock}
                inputProps={{ minLength: 6, maxLength: 12, size: 12 }}
                required
              />

              <Button type="submit">Entrar</Button>
            </Form>

            <Link to="/forgotPass">
              <IconKey size={22} /> Esqueceu a senha?
            </Link>
          </Content>
        </BannerArea>
      </Banner>
      <Footer />
    </>
  );
}
