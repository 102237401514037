import axios from 'axios';

const developmentUrl = 'http://localhost:3333';
const productionUrl = 'https://api.inovacomissariadeavarias.com.br';

const urlCurrency =
  process.env.REACT_APP_ENV === 'development' ? developmentUrl : productionUrl;

const api = axios.create({
  baseURL: urlCurrency,
});

export default api;
