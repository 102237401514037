import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';
import Button from '../../components/Button';
import coloricon from '../../styles/coloricon';
import api from '../../services/api';
import ReactTable from '../../components/ReactTable';
import Modal from '../../components/Modal';
import { Container, Content, GroupButton } from './styles';
import Breadcrumbs from '../../components/BreadcrumbsMUI';
import Loading from '../../components/Loading';

export default function SinisterManager() {
  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [itemModal, setItemModal] = useState({});
  const { IconDelete, IconEdit, IconImage, IconDocument } = coloricon;
  const [sinisters, setSinisters] = useState([]);

  useEffect(() => {
    async function loaderServices() {
      try {
        const response = await api.get('/sinisters');
        setSinisters(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error.response.data.error);
      }
    }
    loaderServices();
  }, []);

  const handleDelete = useCallback(async () => {
    try {
      const response = await api.delete(`/sinisters/${itemModal.id}`);
      const newArray = sinisters.filter((item) => item.id !== itemModal.id);
      setSinisters(newArray);
      setOpenModal(false);
      toast.info(response.data.message);
      setItemModal({});
    } catch (error) {
      toast.info(
        error?.response.data.error || 'Falha no processamento das informações!'
      );
    }
  }, [itemModal, sinisters]);

  const handleSetModal = useCallback((model) => {
    setItemModal(model);
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setItemModal({});
  }, []);

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <Breadcrumbs
        nameOfCurrentPage="Gerenciamento de Sinistros"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/sinisterManager',
          },
        ]}
      />

      <Container>
        <Content>
          <h1>Sinistros</h1>

          {sinisters && sinisters.length === 0 && (
            <Button
              type="button"
              onClick={() => navigation({ pathname: '/createSinister' })}
            >
              Adicionar sinistro
            </Button>
          )}

          {sinisters && sinisters.length > 0 && (
            <GroupButton>
              <Button
                type="button"
                onClick={() => navigation({ pathname: '/createSinister' })}
              >
                Adicionar sinistro
              </Button>
            </GroupButton>
          )}

          {sinisters && sinisters.length === 0 && (
            <p>Não existem registros para exibir.</p>
          )}

          {sinisters && sinisters.length > 0 && (
            <ReactTable
              data={sinisters}
              columns={[
                {
                  Header: 'Nº Sinistro',
                  accessor: 'sinister_number',
                  width: '50%',
                },
                {
                  Header: 'Tipo',
                  accessor: 'sinister_type',
                  width: '50%',
                },
                {
                  Header: 'Data',
                  Cell: ({ row }) =>
                    format(parseISO(row.original.sinister_date), 'dd/MM/yyyy', {
                      locale: pt,
                    }),
                  width: '50%',
                },

                {
                  Header: 'Fotos',
                  Cell: ({ row }) => (
                    <Link to={`/pictures/${row.original.id}`}>
                      <IconImage />
                    </Link>
                  ),
                },
                {
                  Header: 'Docs',
                  Cell: ({ row }) => (
                    <Link to={`/documents/${row.original.id}`}>
                      <IconDocument />
                    </Link>
                  ),
                },
                {
                  Header: 'Editar',
                  Cell: ({ row }) => (
                    <Link to={`/updateSinister/${row.original.id}`}>
                      <IconEdit />
                    </Link>
                  ),
                },
                {
                  Header: 'Excluir',
                  Cell: ({ row }) => (
                    <IconDelete onClick={() => handleSetModal(row.original)} />
                  ),
                },
              ]}
            />
          )}
        </Content>
        <Modal
          title="Confirma a exclusão do sinistro?"
          isOpen={openModal}
          nameOfItem={`Número: ${itemModal.sinister_number}`}
          onClickClose={handleCloseModal}
          onClickAction={handleDelete}
        />
      </Container>
    </>
  );
}
