import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import { Container, ContainerError } from './styles';

export default function Radio({ name, label, options, ...rest }) {
  const inputRefs = useRef([]);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs,
      getValue: (refs) => refs.current.find((input) => input?.checked)?.value,
      setValue: (refs, id) => {
        const inputRef = refs.current.find((ref) => ref.id === id);
        if (inputRef) inputRef.checked = true;
      },
      clearValue: (refs) => {
        const inputRef = refs.current.find((ref) => ref.checked === true);
        if (inputRef) inputRef.checked = false;
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      {label && <p>{label}</p>}

      {options.map((option, index) => (
        <div key={option.id}>
          <span>
            <input
              style={{ display: 'block' }}
              type="radio"
              ref={(ref) => {
                inputRefs.current[index] = ref;
              }}
              id={option.id}
              defaultChecked={option.selected === true}
              name={name}
              value={option.value}
              {...rest}
            />

            <label htmlFor={option.id} key={option.id}>
              {option.label}
            </label>
          </span>
        </div>
      ))}

      <ContainerError>
        {error && <FiAlertCircle size={20} />}
        {error && <span>{error}</span>}
      </ContainerError>
    </Container>
  );
}

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.func,
  ]),
};

Radio.defaultProps = {
  options: [],
};

/* defaultChecked={option.id} */
