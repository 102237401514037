import React from 'react';
import PropTypes from 'prop-types';
import { Overlay, Background, ModalContent } from './styles';
import Button from '../Button';
import coloricon from '../../styles/coloricon';

const { IconClose } = coloricon;

export default function Modal({
  title,
  isOpen,
  onClickClose,
  onClickAction,
  nameOfItem,
}) {
  if (isOpen === false) {
    return null;
  }

  return (
    <Overlay>
      <Background>
        <button type="button" onClick={onClickClose} aria-label="close">
          <IconClose />
        </button>
        <ModalContent>
          <h2>{title}</h2>
          <strong>{nameOfItem}</strong>

          <div>
            <Button type="button" onClick={onClickClose}>
              Cancelar
            </Button>
            <Button type="button" onClick={onClickAction}>
              Confirmar
            </Button>
          </div>
        </ModalContent>
      </Background>
    </Overlay>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  nameOfItem: PropTypes.string,
  onClickAction: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  isOpen: false,
  nameOfItem: null,
};
