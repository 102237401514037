import * as Yup from 'yup';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { mask, unMask } from 'remask';
import { parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import Input from '../../components/Input';
import Loading from '../../components/Loading';
import Button from '../../components/Button';
import DateInput from '../../components/DatePickerInput';
import api from '../../services/api';
import coloricon from '../../styles/coloricon';
import { GroupDate, Container, Content } from './styles';

export default function InsuranceInput({ idSinister }) {
  const formRefInsurance = useRef(null);
  const [loading, setLoading] = useState(true);
  const [insurance, setInsurance] = useState(null);
  const { IconService, IconDocument, IconUser, IconPhone } = coloricon;

  const [insurance_date, setInsurance_date] = useState(new Date());
  const [insurance_cpf_cnpj, setInsurance_cpf_cnpj] = useState('');
  const [insurance_contact_phone, setInsurance_contact_phone] = useState('');
  const [insurance_analyst_phone, setInsurance_analyst_phone] = useState('');

  useEffect(() => {
    async function loaderItems() {
      try {
        const response = await api.get(`/sinisters/${idSinister}`);

        setInsurance_date(
          response.data.insurance.insurance_date
            ? parseISO(response.data.insurance.insurance_date)
            : new Date()
        );

        setInsurance_cpf_cnpj(
          response.data.insurance.insurance_cpf_cnpj
            ? response.data.insurance.insurance_cpf_cnpj
            : ''
        );

        setInsurance_contact_phone(
          response.data.insurance.insurance_contact_phone
            ? response.data.insurance.insurance_contact_phone
            : ''
        );

        setInsurance_analyst_phone(
          response.data.insurance.insurance_analyst_phone
            ? response.data.insurance.insurance_analyst_phone
            : ''
        );
        setInsurance(response.data.insurance);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(
          error?.response.data.error || 'Falha no carregamento das informações!'
        );
      }
    }
    loaderItems();
  }, [idSinister]);

  const onChangeMaskedInsurance_cpf_cnpj = useCallback((event) => {
    const originalValue = unMask(event.target.value);
    const masked = mask(originalValue, [
      '999.999.999-99',
      '99.999.999/9999-99',
    ]);
    setInsurance_cpf_cnpj(masked);
  }, []);

  const onChangeMaskedInsurance_contact_phone = useCallback((event) => {
    const originalValue = unMask(event.target.value);
    const masked = mask(originalValue, ['(99) 9999-9999', '(99) 99999-9999']);
    setInsurance_contact_phone(masked);
  }, []);

  const onChangeMaskedInsurance_analyst_phone = useCallback((event) => {
    const originalValue = unMask(event.target.value);
    const masked = mask(originalValue, ['(99) 9999-9999', '(99) 99999-9999']);
    setInsurance_analyst_phone(masked);
  }, []);

  const handleSubmitInsurance = useCallback(
    async (data) => {
      try {
        const schemaValidation = Yup.object().shape({
          insurance_date: Yup.date().required('A data é obrigatória!'),

          insurance_company: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O nome da seguradora é obrigatória!'),
            otherwise: Yup.string(),
          }),

          insurance_number: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O número do sinistro é obrigatório!'),
            otherwise: Yup.string(),
          }),

          insurance_cpf_cnpj_formatted: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required('O CPF/CNPJ é obrigatório!')
              .min(14, 'O CPF/CNPJ precisa mínimo 14 dígitos!')
              .max(18, 'O CPF/CNPJ suporta máximo 18 dígitos!'),
            otherwise: Yup.string(),
          }),

          insurance_contact: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O nome do responsável é obrigatório!'),
            otherwise: Yup.string(),
          }),

          insurance_contact_phone_formatted: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required('O telefone é obrigatório!')
              .min(14, 'O telefone precisa mínimo 10 dígitos!')
              .max(15, 'O telefone suporta máximo 11 dígitos!'),
            otherwise: Yup.string(),
          }),

          insurance_analyst: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O nome do analista é obrigatório!'),
            otherwise: Yup.string(),
          }),

          insurance_analyst_phone_formatted: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required('O telefone é obrigatório!')
              .min(14, 'O telefone precisa mínimo 10 dígitos!')
              .max(15, 'O telefone suporta máximo 11 dígitos!'),
            otherwise: Yup.string(),
          }),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        const dataFormatted = {
          ...data,
          insurance_cpf_cnpj: data.insurance_cpf_cnpj_formatted
            ? data.insurance_cpf_cnpj_formatted
            : null,
          insurance_contact_phone: data.insurance_contact_phone_formatted
            ? data.insurance_contact_phone_formatted
            : null,
          insurance_analyst_phone: data.insurance_analyst_phone_formatted
            ? data.insurance_analyst_phone_formatted
            : null,
        };

        await api.put(`/sinisters/${idSinister}`, {
          insuranceReceived: dataFormatted,
        });

        toast.info('O seguro foi atualizado com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            toast.info(
              `Falha no preenchimento do campo ${error.path}. Mensagem: ${error.message}`
            );
          });
        } else {
          toast.info(
            err?.response.data.error ||
              'Falha no processamento das informações!'
          );
        }
      }
    },
    [idSinister]
  );

  return loading === true ? (
    <Loading />
  ) : (
    <Container>
      <Content>
        <Form
          ref={formRefInsurance}
          onSubmit={handleSubmitInsurance}
          initialData={insurance}
        >
          <Input
            name="insurance_company"
            type="text"
            icon={IconService}
            placeholder="Nome seguradora"
          />

          <Input
            name="insurance_number"
            type="text"
            icon={IconDocument}
            placeholder="Número do sinistro"
          />

          <GroupDate>
            <h3>Data sinistro:</h3>
            <DateInput
              name="insurance_date"
              selected={insurance_date}
              onChange={(date) => setInsurance_date(date)}
            />
          </GroupDate>

          <Input
            name="insurance_cpf_cnpj_formatted"
            placeholder="CPF/CNPJ"
            type="text"
            value={insurance_cpf_cnpj}
            onChange={onChangeMaskedInsurance_cpf_cnpj}
            icon={IconDocument}
          />
          <Input
            name="insurance_contact"
            type="text"
            icon={IconUser}
            placeholder="Responsável"
          />
          <Input
            name="insurance_contact_phone_formatted"
            type="text"
            value={insurance_contact_phone}
            onChange={onChangeMaskedInsurance_contact_phone}
            icon={IconPhone}
            placeholder="DDD + Telefone - responsável"
          />
          <Input
            name="insurance_analyst"
            type="text"
            icon={IconUser}
            placeholder="Analista"
          />
          <Input
            name="insurance_analyst_phone_formatted"
            type="text"
            icon={IconPhone}
            value={insurance_analyst_phone}
            onChange={onChangeMaskedInsurance_analyst_phone}
            placeholder="DDD + Telefone - analista"
          />
          <Button type="subimit">Salvar</Button>
        </Form>
      </Content>
    </Container>
  );
}
