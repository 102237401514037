import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';
import {
  FiChevronDown,
  FiChevronUp,
  FiChevronsRight,
  FiChevronsLeft,
  FiChevronRight,
  FiChevronLeft,
} from 'react-icons/fi';
import {
  TableContainer,
  Select,
  Input,
  Button,
  PaginationArea,
} from './styles';
import coloricon from '../../styles/coloricon';
import ColumnFilter from './ColumnFilter';

export default function ReactTable({ columns, data }) {
  const colsTable = useMemo(() => columns, [columns]);
  const rowsTable = useMemo(() => data, [data]);
  const defaultColumn = useMemo(
    () => ({
      Filter: ColumnFilter,
    }),
    []
  );

  /* para desabilitar disableFilters: true
  se inserir Filter: false ele retira o filtro também para ganhar espaço
  */

  const { secondaryColor } = coloricon;

  const tableInstance = useTable(
    {
      columns: colsTable,
      data: rowsTable,
      defaultColumn,
    },
    useFilters,
    useSortBy, // precisa ser nessa sequencia
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize, // quantidade de registros
    prepareRow,
  } = tableInstance;

  const { pageIndex, pageSize } = state;

  return (
    <>
      <TableContainer {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* aqui vai a classificacao */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FiChevronDown
                          style={{ marginLeft: 2.5 }}
                          color={secondaryColor}
                        />
                      ) : (
                        <FiChevronUp
                          style={{ marginLeft: 2.5 }}
                          color={secondaryColor}
                        />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                  {/* aqui vai os filtros */}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </TableContainer>
      {/* pagination */}
      <PaginationArea>
        <span>
          Página
          <strong>
            {' '}
            {pageIndex + 1} de {pageOptions.length}
          </strong>
        </span>

        <span>
          Ir para
          <Input
            min={1}
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(event) => {
              const pageNumber = event.target.value
                ? Number(event.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
          />
        </span>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 15,
          }}
        >
          <Button
            type="button"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FiChevronsLeft />
          </Button>
          <Button
            type="button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <FiChevronLeft />
          </Button>
          <Button
            type="button"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <FiChevronRight />
          </Button>
          <Button
            type="button"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <FiChevronsRight />
          </Button>
        </div>

        <Select
          value={pageSize}
          onChange={(event) => setPageSize(Number(event.target.value))}
        >
          {[10, 25, 50, 100].map((showPages) => (
            <option key={showPages} value={showPages}>
              Exibir {showPages} registros.
            </option>
          ))}
        </Select>
      </PaginationArea>
    </>
  );
}

ReactTable.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.array, PropTypes.any]).isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.any]).isRequired,
};
