import React from 'react';
import Header from '../../components/Header';
import { Banner, BannerArea, Content } from './styles';
import Footer from '../../components/Footer';

export default function Home() {
  return (
    <>
      <Header />
      <Banner>
        <BannerArea>
          <Content>
            <h1>Seja nosso parceiro!</h1>
            <p>
              Nossa empresa cuida dos trâmites do sinistro e aciona os orgãos
              competentes para que você tenha o tratamento adequado para cada
              etapa do processo e gerando economia financeira para seu negócio!
            </p>
          </Content>
        </BannerArea>
      </Banner>
      <Footer />
    </>
  );
}
