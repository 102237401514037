import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { secondaryColor, fontColor } = coloricon;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    font-size: 15px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }

  > h1 {
    margin: 15px 0px;
    text-align: center;
  }

  > h2 {
    margin-bottom: 15px;
    color: ${secondaryColor};
    text-align: center;
  }

  > p {
    margin-bottom: 15px;
    text-align: center;
  }

  > button {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    height: auto;
    padding: 15px;

    @media (max-width: 425px) {
      flex-direction: column;
    }

    svg {
      font-size: 20px;
    }

    span {
      font-size: 1em;
      margin-left: 5px;
      color: ${fontColor};
    }
  }
`;

export const Link = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${fontColor};
  transition: all 0.3s ease-in-out;
  margin: 10px 0px;

  > svg {
    font-size: 20px;
  }

  &:hover {
    color: ${secondaryColor};
  }
`;
