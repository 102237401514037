import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import api from '../../services/api';
import coloricon from '../../styles/coloricon';
import ReactTable from '../../components/ReactTable';
import { Container, Content, Link } from './styles';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import BlockUI from '../../components/BlockUi';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/BreadcrumbsMUI';

export default function Document() {
  const [blockUI, setBlockUI] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [itemModal, setItemModal] = useState({});
  const { idSinister } = useParams();
  const { IconDocument, IconDelete, IconDownload, IconSearch } = coloricon;

  useEffect(() => {
    async function loaderDocumentList() {
      try {
        const response = await api.get(`/documents/${idSinister}`);
        setDocumentList(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error.response.data.error);
      }
    }
    loaderDocumentList();
  }, [idSinister]);

  const uploadDocument = useCallback(
    async (event) => {
      try {
        setBlockUI(true);
        const data = new FormData();
        data.append('file', event.target.files[0]);
        const response = await api.post(`/documents/${idSinister}`, data);
        const newArray = [...documentList, response.data];
        setDocumentList(newArray);
        setBlockUI(false);
        toast.info(
          response.data?.message || 'O documento armazenado com sucesso!'
        );
      } catch (error) {
        setBlockUI(false);
        toast.info(error?.response.data.error || 'Falha no processamento!');
      }
    },
    [documentList, idSinister]
  );

  const deleteDocument = useCallback(async () => {
    try {
      setBlockUI(true);
      const response = await api.delete(
        `/documents/${idSinister}/${itemModal.key}`
      );
      setDocumentList(
        documentList.filter((item) => item.key !== itemModal.key)
      );
      setOpenModal(false);
      setItemModal({});
      setBlockUI(false);
      toast.info(response.data.message);
    } catch (error) {
      setBlockUI(false);
      toast.info(error.response.data.error);
    }
  }, [documentList, itemModal, idSinister]);

  const handleOpenModal = useCallback((doc) => {
    setItemModal(doc);
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setItemModal({});
  }, []);

  const handleDownloadByUrl = useCallback((file) => {
    setBlockUI(true);
    const urlFile = file.url;
    const nameFile = file.name;

    fetch(urlFile, {
      mode: 'no-cors', //! propriedade que permite fazer o download
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        // Create an anchor element
        const link = document.createElement('a');

        // Set the href attribute to the image URL
        link.href = blobURL;

        // Set the download attribute with the desired filename
        link.setAttribute('download', nameFile);

        // Append the anchor element to the document
        document.body.appendChild(link);

        // Trigger a click event on the anchor element to start the download
        link.click();
        link.remove();
        setBlockUI(false);
      })
      .catch((err) => {
        setBlockUI(false);
        if (err) {
          toast.info('Erro ao realizar o download!');
        }
      });
  }, []);

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <Breadcrumbs
        nameOfCurrentPage="Gerenciamento de documentos"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/sinisterManager',
          },
        ]}
      />
      <BlockUI blocking={blockUI} />
      <Container>
        <Content>
          <h1>Documentos do sinistro</h1>

          <Button
            type="button"
            onClick={() => document.getElementById('file').click()}
          >
            <IconDocument />
            <span>Adicionar documento</span>
          </Button>

          {documentList && documentList.length === 0 && (
            <p>Não existem registros para exibir.</p>
          )}

          <input
            type="file"
            onChange={uploadDocument}
            id="file"
            name="file"
            style={{ display: 'none' }}
          />

          {documentList && documentList.length > 0 && (
            <ReactTable
              data={documentList}
              columns={[
                {
                  Header: 'Name',
                  accessor: 'name',
                  width: '50%',
                  disableFilters: true,
                },
                {
                  Header: 'Visualizar',
                  Cell: ({ row }) => (
                    <Link
                      href={row.original.url}
                      without="true"
                      rel="noopener noreferrer"
                      target="_blank"
                      crossorigin="anonymous"
                    >
                      <IconSearch />
                    </Link>
                  ),
                },
                {
                  Header: 'Dowload',
                  Cell: ({ row }) => (
                    <Link
                      onClick={() => handleDownloadByUrl(row.original)}
                      crossorigin="anonymous"
                    >
                      <IconDownload />
                    </Link>
                  ),
                },
                {
                  Header: 'Excluir',
                  Cell: ({ row }) => (
                    <Link onClick={() => handleOpenModal(row.original)}>
                      <IconDelete />
                    </Link>
                  ),
                },
              ]}
            />
          )}
        </Content>
        <Modal
          nameOfItem={itemModal.name}
          title="Confirma a exclusão do documento?"
          isOpen={openModal}
          onClickClose={handleCloseModal}
          onClickAction={deleteDocument}
        />
      </Container>
    </>
  );
}
