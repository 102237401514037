import styled, { css } from 'styled-components';
import coloricon from '../../styles/coloricon';

const { backgroundHeader, fontColor, secondaryColor } = coloricon;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: ${backgroundHeader};
  height: 80px;

  @media (max-width: 700px) {
    font-size: 14px;
    flex-direction: column;
    height: auto;
    padding: 30px 0px;
  }
`;

export const ContentLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    margin-bottom: 25px;
  }

  @media (max-width: 425px) {
    flex-direction: column;

    > img {
      margin-bottom: 10px;
    }
  }

  > svg {
    font-size: 30px;
    color: ${secondaryColor};
    margin-right: 10px;
  }

  > h1 {
    font-size: 1.3em;
    padding-left: 5px;
    color: ${secondaryColor};
  }
`;

export const ContentMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  nav ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: 425px) {
      flex-direction: column;
    }
  }

  /* nav ul li button {
    display: block;
    cursor: pointer;
    color: ${fontColor};
    background-color: transparent;
    border: 0;
    padding: 10px;
    font-size: 1.1em;
  } */
`;

export const Button = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 10px;
  font-size: 1.1em;

  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${fontColor};
  transition: all 0.3s ease-in-out;

  > svg {
    margin-bottom: -2px;
  }

  &:hover {
    color: ${secondaryColor};
  }

  ${(props) =>
    props.current === 'true' &&
    css`
      color: ${secondaryColor};
      transition: all 0.3s ease-in-out;

      &:hover {
        color: ${secondaryColor};
      }
    `}

  ${(props) =>
    props.current === 'false' &&
    css`
      color: ${fontColor};
      transition: all 0.3s ease-in-out;

      &:hover {
        color: ${secondaryColor};
      }
    `}
`;
