import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Drawer from '../components/Drawer';
import { menuUser } from '../components/Drawer/menu';

// private rules
import UserRoutes from './UserRoutes';

// public access
import Home from '../pages/Home';
import Login from '../pages/Login';
import Contact from '../pages/ContactForm';
import ForgotPass from '../pages/ForgotPass';
import ResetAccessPlatform from '../pages/ResetAccessPlatform';
import About from '../pages/About';
import Services from '../pages/Services';

// user private
import ProfileUser from '../pages/ProfileUser';
import DashUser from '../pages/DashUser';
import SinisterManager from '../pages/SinisterManager';
import CreateSinister from '../pages/CreateSinister';
import UpdateSinister from '../pages/UpdateSinister';
import Picture from '../pages/Picture';
import Document from '../pages/Document';
import Report from '../pages/Report';

// 404 not found
import PageNotFound from '../pages/PageNotFound';

export default function AllRoutes() {
  return (
    <Routes>
      {/* public routes */}
      <Route path="/" exact element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/resetAccessPlatform/:token"
        element={<ResetAccessPlatform />}
      />
      <Route path="/forgotPass" element={<ForgotPass />} />
      <Route path="/about" element={<About />} />
      <Route path="/services" element={<Services />} />
      <Route path="/contactus" element={<Contact />} />

      {/* private routes */}
      <Route
        exact
        path="/"
        element={
          <Drawer menu={menuUser}>
            <UserRoutes />
          </Drawer>
        }
      >
        <Route path="/profileUser" element={<ProfileUser />} isPrivate />
        <Route path="/dashUser" element={<DashUser />} isPrivate />
        <Route
          path="/sinisterManager"
          element={<SinisterManager />}
          isPrivate
        />
        <Route path="/createSinister" element={<CreateSinister />} isPrivate />
        <Route
          path="/updateSinister/:idSinister"
          element={<UpdateSinister />}
          isPrivate
        />
        <Route path="/pictures/:idSinister" element={<Picture />} isPrivate />
        <Route path="/documents/:idSinister" element={<Document />} isPrivate />
        <Route path="/reports" element={<Report />} isPrivate />
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
