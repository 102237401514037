import React, { createContext, useMemo } from 'react';
import userContext from './userContext';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const { dataUser, setDataUser, handleLoginUser, handleLogoutUser } =
    userContext();

  /* para ele nao ficar renderizando de forma desnecessaria */
  const contextValues = useMemo(
    () => ({
      dataUser,
      setDataUser,
      handleLoginUser,
      handleLogoutUser,
    }),
    [dataUser, setDataUser, handleLoginUser, handleLogoutUser]
  );

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
