import * as Yup from 'yup';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { mask, unMask } from 'remask';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import api from '../../services/api';
import coloricon from '../../styles/coloricon';
import { Container, Content } from './styles';

export default function BrokerInput({ idSinister }) {
  const formRefBroker = useRef(null);
  const [loading, setLoading] = useState(true);
  const [broker, setBroker] = useState(null);
  const { IconService, IconUser, IconPhone, IconMail } = coloricon;
  const [broker_contact_phone, setBroker_contact_phone] = useState('');

  const onChangeMaskedBroker_contact_phone = useCallback((event) => {
    const originalValue = unMask(event.target.value);
    const masked = mask(originalValue, ['(99) 9999-9999', '(99) 99999-9999']);
    setBroker_contact_phone(masked);
  }, []);

  useEffect(() => {
    async function loaderItems() {
      try {
        const response = await api.get(`/sinisters/${idSinister}`);

        setBroker_contact_phone(
          response.data.broker && response.data.broker.broker_contact_phone
            ? response.data.broker.broker_contact_phone
            : ''
        );
        setBroker(response.data.broker);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(
          error?.response.data.error || 'Falha no carregamento das informações!'
        );
      }
    }
    loaderItems();
  }, [idSinister]);

  const handleSubmitBroker = useCallback(
    async (data) => {
      try {
        const schemaValidation = Yup.object().shape({
          broker_company: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O nome da corretora é obrigatória!'),
            otherwise: Yup.string(),
          }),

          broker_contact: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O responsável é obrigatório!'),
            otherwise: Yup.string(),
          }),

          broker_contact_phone_formatted: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required('O telefone é obrigatório!')
              .min(14, 'O telefone precisa mínimo 10 dígitos!')
              .max(15, 'O telefone suporta máximo 11 dígitos!'),
            otherwise: Yup.string(),
          }),

          broker_contact_mail: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().email().required('O email é obrigatório!'),
            otherwise: Yup.string(),
          }),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        const dataFormatted = {
          ...data,
          broker_contact_phone: data.broker_contact_phone_formatted
            ? data.broker_contact_phone_formatted
            : null,
        };

        await api.put(`/sinisters/${idSinister}`, {
          brokerReceived: dataFormatted,
        });

        toast.info('Os dados foram atualizados com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            toast.info(
              `Falha no preenchimento do campo ${error.path}. Mensagem: ${error.message}`
            );
          });
        } else {
          toast.info(
            err?.response.data.error ||
              'Falha no processamento das informações!'
          );
        }
      }
    },
    [idSinister]
  );

  return loading === true ? (
    <Loading />
  ) : (
    <Container>
      <Content>
        <Form
          ref={formRefBroker}
          initialData={broker}
          onSubmit={handleSubmitBroker}
        >
          <Input
            name="broker_company"
            type="text"
            icon={IconService}
            placeholder="Nome da corretora"
          />
          <Input
            name="broker_contact"
            type="text"
            icon={IconUser}
            placeholder="Nome do responsável"
          />
          <Input
            name="broker_contact_phone_formatted"
            type="text"
            value={broker_contact_phone}
            onChange={onChangeMaskedBroker_contact_phone}
            icon={IconPhone}
            placeholder="DDD + Telefone"
          />
          <Input
            name="broker_contact_mail"
            type="email"
            icon={IconMail}
            placeholder="Email"
          />
          <Button type="submit">Salvar</Button>
        </Form>
      </Content>
    </Container>
  );
}
