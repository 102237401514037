import styled from 'styled-components';
import banner from '../../assets/banner.jpg';
import coloricon from '../../styles/coloricon';

const { secondaryColor } = coloricon;

const imageChoosed = banner;

export const Banner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(${imageChoosed}) no-repeat center;
  background-size: cover;
  height: calc(100vh - 80px - 100px);
  width: 100%;

  @media (max-width: 400px) {
    height: auto;
  }
`;

export const BannerArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: inherit;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  padding: 40px;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 15px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }

  h1 {
    color: ${secondaryColor};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.8em;
  }

  p {
    line-height: 1.6;
    margin-top: 10px;
    font-size: 1.5em;

    span {
      color: ${secondaryColor};
    }
  }
`;
