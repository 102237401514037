import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Header, ContentLogo, ContentMenu, Button } from './styles';
import coloricon from '../../styles/coloricon';

const { IconPackage, IconUser } = coloricon;

export default function HeaderHome() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const home = pathname === '/' ? 'true' : 'false';
  const about = pathname === '/about' ? 'true' : 'false';
  const services = pathname === '/services' ? 'true' : 'false';
  const contactus = pathname === '/contactus' ? 'true' : 'false';

  return (
    <Header>
      <ContentLogo>
        <IconPackage />
        <h1>Inova Comissária de Avarias</h1>
      </ContentLogo>

      <ContentMenu>
        <nav>
          <ul>
            <li>
              <Button
                type="button"
                current={home}
                onClick={() => {
                  navigate({ pathname: '/' });
                }}
              >
                Início
              </Button>
            </li>
            <li>
              <Button
                onClick={() => {
                  navigate({ pathname: '/about' });
                }}
                type="button"
                current={about}
              >
                Quem somos
              </Button>
            </li>
            <li>
              <Button
                current={services}
                onClick={() => {
                  navigate({ pathname: '/services' });
                }}
                type="button"
              >
                Serviços
              </Button>
            </li>
            <li>
              <Button
                current={contactus}
                onClick={() => {
                  navigate({ pathname: '/contactus' });
                }}
                type="button"
              >
                Contato
              </Button>
            </li>
            <li>
              <Button
                type="button"
                onClick={() => navigate({ pathname: '/login' })}
              >
                <IconUser size={20} style={{ marginRight: 5 }} />
                Entrar
              </Button>
            </li>
          </ul>
        </nav>
      </ContentMenu>
    </Header>
  );
}
