import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { mask, unMask } from 'remask';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Backdrop, CircularProgress } from '@material-ui/core';
import coloricon from '../../styles/coloricon';
import { Container, Content } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../services/api';
import { AuthContext } from '../../context/AuthContext';

export default function ProfileUser() {
  const [loading, setLoading] = useState(true);
  const { IconUser, IconPhone, IconMail, IconLock } = coloricon;
  const [profile, setProfile] = useState({});
  const [maskPhone, setMaskPhone] = useState('');
  const formRef = useRef(null);
  const { setDataUser } = useContext(AuthContext);

  const onChangeMaskPhone = useCallback((event) => {
    const originalValue = unMask(event.target.value);
    const maskedValue = mask(originalValue, ['(99) 99999-9999']);
    setMaskPhone(maskedValue);
  }, []);

  useEffect(() => {
    async function loaderProfile() {
      try {
        const response = await api.get('/users');
        setProfile(response.data);
        setMaskPhone(response.data.phone || '');
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error.response.data.error);
      }
    }
    loaderProfile();
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        // montando o novo objeto que vem do data para nao pegar vazio
        const newDataFiltered = {};
        Object.entries(data).forEach(([key, value]) => {
          if (key === 'cpfFormatted') {
            newDataFiltered.cpf = value;
            return;
          }
          newDataFiltered[key === 'phoneFormatted' ? 'phone' : key] = value;
        });

        const schemaValidation = Yup.object().shape({
          name: Yup.string()
            .required('O nome é obrigatório!')
            .min(3, 'O nome precisa mínimo 3 caracteres!')
            .max(75, 'O nome suporta máximo 75 caracteres'),

          phoneFormatted: Yup.string()
            .required('O celular é obrigatório!')
            .min(15, 'O celular precisa mínimo 11 dígitos!')
            .max(15, 'O celular suporta máximo 11 dígitos!'),

          email: Yup.string()
            .email('Endereço inválido!')
            .required('O e-mail é obrigatório!'),

          oldPassword: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required('A senha atual é obrigatória!')
              .min(6, 'A senha atual precisa mínimo 6 dígitos!')
              .max(12, 'A senha atual suporta máximo 12 dígitos!'),
            otherwise: Yup.string(),
          }),

          password: Yup.string().when('oldPassword', {
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required('A nova senha é obrigatória!')
              .min(6, 'A nova senha precisa mínimo 6 dígitos!')
              .max(12, 'A nova senha suporta máximo 12 dígitos!'),
            otherwise: Yup.string(),
          }),

          confirmPassword: Yup.string().when('password', {
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required('A confirmação da senha é obrigatória!')
              .oneOf(
                [Yup.ref('password')],
                'A nova senha e a confirmação são diferentes!'
              ),
            otherwise: Yup.string(),
          }),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        formRef.current.setErrors({});

        const response = await api.put('/users', newDataFiltered);

        formRef.current.setData({
          name: response.data.userUpdated.name,
          email: response.data.userUpdated.email,
          phoneFormatted: response.data.userUpdated.phone,
          oldPassword: null,
          password: null,
          confirmPassword: null,
        });

        const user = {
          id: response.data.userUpdated.id,
          name: response.data.userUpdated.name,
        };

        localStorage.setItem(
          `@${process.env.REACT_APP_NAME}:user`,
          JSON.stringify(user)
        );

        setDataUser({ user });

        toast.info(response.data.message);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};
          err.inner.forEach((error) => {
            errorMessages[error.path] = error.message;
          });
          formRef.current.setErrors(errorMessages);
        } else {
          toast.info(err.response.data.error);
        }
      }
    },
    [setDataUser]
  );

  return loading === true ? (
    <Backdrop open>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Container>
      <Content>
        <h1>Perfil</h1>
        <Form ref={formRef} initialData={profile} onSubmit={handleSubmit}>
          <Input
            type="text"
            name="name"
            placeholder="Nome e sobrenome"
            required
            icon={IconUser}
            inputProps={{ minLength: 3, maxLength: 75, size: 75 }}
          />

          <Input
            type="text"
            name="phoneFormatted"
            placeholder="DDD + Celular"
            required
            icon={IconPhone}
            onChange={onChangeMaskPhone}
            inputProps={{ maxLength: 15, size: 15 }}
            value={maskPhone}
          />
          <Input
            type="email"
            name="email"
            placeholder="E-mail"
            required
            icon={IconMail}
          />

          <Input
            type="password"
            name="oldPassword"
            autoComplete="on"
            placeholder="Senha atual"
            icon={IconLock}
            inputProps={{ minLength: 6, maxLength: 12, size: 12 }}
          />
          <Input
            type="password"
            name="password"
            autoComplete="on"
            placeholder="Nova senha"
            icon={IconLock}
            inputProps={{ minLength: 6, maxLength: 12, size: 12 }}
          />
          <Input
            type="password"
            name="confirmPassword"
            autoComplete="on"
            placeholder="Confirmação"
            icon={IconLock}
            inputProps={{ minLength: 6, maxLength: 12, size: 12 }}
          />
          <Button type="submit">Atualizar</Button>
        </Form>
      </Content>
    </Container>
  );
}
