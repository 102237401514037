import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import api from '../../services/api';
import coloricon from '../../styles/coloricon';
import ReactTable from '../../components/ReactTable';
import { Container, Content, Link } from './styles';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import BlockUI from '../../components/BlockUi';
import Breadcrumbs from '../../components/BreadcrumbsMUI';

export default function Picture() {
  const [loading, setLoading] = useState(true);
  const [blockUI, setBlockUI] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [itemModal, setItemModal] = useState({});
  const { idSinister } = useParams();
  const { IconCamera, IconDelete, IconDownload, IconSearch } = coloricon;

  useEffect(() => {
    async function loaderImageList() {
      try {
        const response = await api.get(`/pictures/${idSinister}`);
        setImageList(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error.response.data.error);
      }
    }
    loaderImageList();
  }, [idSinister]);

  const handleChangeImage = useCallback(
    async (event) => {
      try {
        setBlockUI(true);
        const data = new FormData();
        data.append('file', event.target.files[0]);
        const response = await api.post(`/pictures/${idSinister}`, data);
        const newArray = [...imageList, response.data];
        setImageList(newArray);
        setBlockUI(false);
        toast.info(
          response.data?.message || 'A foto foi armazenada com sucesso!'
        );
      } catch (error) {
        setBlockUI(false);
        toast.info(
          error?.response.data.error ||
            'Falha no processamento das informações!'
        );
      }
    },
    [imageList, idSinister]
  );

  const handleDeleteImage = useCallback(async () => {
    try {
      setBlockUI(true);
      const response = await api.delete(
        `/pictures/${idSinister}/${itemModal.key}`
      );
      setImageList(imageList.filter((item) => item.key !== itemModal.key));
      setOpenModal(false);
      setItemModal({});
      setBlockUI(false);
      toast.info(response.data.message);
    } catch (error) {
      setBlockUI(false);
      toast.info(
        error?.response.data.error || 'Falha no processamento das informações!'
      );
    }
  }, [imageList, itemModal, idSinister]);

  const handleOpenModal = useCallback((img) => {
    setItemModal(img);
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setItemModal({});
  }, []);

  const downloadPicture = (urlImage) => {
    setBlockUI(true);
    fetch(urlImage, {
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then((buffer) => {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'image.png'); // or any other extension
          document.body.appendChild(link);
          link.click();
          setBlockUI(false);
        });
      })
      .catch((err) => {
        setBlockUI(false);
        if (err) {
          toast.info('Erro ao realizar o download!');
        }
      });
  };

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <Breadcrumbs
        nameOfCurrentPage="Gerenciamento de fotos"
        arrayOfTitleAndPath={[
          {
            title: 'Início',
            path: '/sinisterManager',
          },
        ]}
      />
      <BlockUI blocking={blockUI} />

      <Container>
        <Content>
          <h1>Fotos da vistoria</h1>

          {imageList && imageList.length === 0 && (
            <p>Não existem registros para exibir.</p>
          )}

          <Button
            type="button"
            onClick={() => document.getElementById('file').click()}
          >
            <IconCamera />
            <span>Adicionar foto</span>
          </Button>

          <input
            type="file"
            onChange={handleChangeImage}
            accept="image/png , image/jpeg , image/jpg"
            id="file"
            name="file"
            style={{ display: 'none' }}
          />

          {imageList && imageList.length > 0 && (
            <ReactTable
              data={imageList}
              columns={[
                {
                  Header: 'Name',
                  accessor: 'name',
                  width: '50%',
                  disableFilters: true,
                },
                {
                  Header: 'Visualizar',
                  Cell: ({ row }) => (
                    <Link
                      href={row.original.url}
                      without="true"
                      rel="noopener noreferrer"
                      target="_blank"
                      crossorigin="anonymous"
                    >
                      <IconSearch />
                    </Link>
                  ),
                },
                {
                  Header: 'Dowload',
                  Cell: ({ row }) => (
                    <Link
                      onClick={() => downloadPicture(row.original)}
                      crossorigin="anonymous"
                    >
                      <IconDownload />
                    </Link>
                  ),
                },
                {
                  Header: 'Excluir',
                  Cell: ({ row }) => (
                    <Link onClick={() => handleOpenModal(row.original)}>
                      <IconDelete />
                    </Link>
                  ),
                },
              ]}
            />
          )}
        </Content>
        <Modal
          nameOfItem={itemModal.name}
          title="Confirma a exclusão da imagem?"
          isOpen={openModal}
          onClickClose={handleCloseModal}
          onClickAction={handleDeleteImage}
        />
      </Container>
    </>
  );
}
