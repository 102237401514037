import * as Yup from 'yup';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import DateInput from '../../components/DatePickerInput';
import api from '../../services/api';
import coloricon from '../../styles/coloricon';
import { GroupDate, Container, Content } from './styles';

export default function SinisterInputs({ idSinister }) {
  const formRefSinister = useRef(null);
  const [loading, setLoading] = useState(true);
  const [sinister, setSinister] = useState(null);
  const { IconDocument, IconPackage, IconPrice, IconTruck } = coloricon;
  const [sinister_date, setSinister_date] = useState(new Date());
  const [trigger_date, setTrigger_date] = useState(new Date());
  const [arrival_forecast, setArrival_forecast] = useState(new Date());

  useEffect(() => {
    async function loaderItems() {
      try {
        const response = await api.get(`/sinisters/${idSinister}`);

        setSinister_date(parseISO(response.data.sinister.sinister_date));

        setTrigger_date(
          response.data.sinister.trigger_date
            ? parseISO(response.data.sinister.trigger_date)
            : new Date()
        );

        setArrival_forecast(
          response.data.sinister.arrival_forecast
            ? parseISO(response.data.sinister.arrival_forecast)
            : new Date()
        );
        setSinister(response.data.sinister);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(
          error?.response.data.error || 'Falha no carregamento das informações!'
        );
      }
    }
    loaderItems();
  }, [idSinister]);

  const handleSubmitSinister = useCallback(
    async (data) => {
      try {
        const schemaValidation = Yup.object().shape({
          sinister_number: Yup.string().required('O número é obrigatório!'),
          sinister_type: Yup.string().required('O tipo é obrigatório!'),
          sinister_event_type: Yup.string().required('O evento é obrigatório!'),

          sinister_cause: Yup.string().required('A causa é obrigatória'),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        const newData = {
          ...data,
          quantity_shipped: data.quantity_shipped
            ? Number(data.quantity_shipped)
            : null,
          quantity_received: data.quantity_received
            ? Number(data.quantity_received)
            : null,
          broken_quantity: data.broken_quantity
            ? Number(data.broken_quantity)
            : null,
          shipping_value: data.shipping_value
            ? Number(data.shipping_value)
            : null,
          damage_estimate: data.damage_estimate
            ? Number(data.damage_estimate)
            : null,
        };

        const payload = {
          sinisterReceived: newData,
        };

        await api.put(`/sinisters/${idSinister}`, payload);

        toast.info('Os dados foram atualizados com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            toast.info(
              `Falha no preenchimento do campo ${error.path}. Mensagem: ${error.message}`
            );
          });
        } else {
          toast.info(
            err?.response.data.error ||
              'Falha no processamento das informações!'
          );
        }
      }
    },
    [idSinister]
  );

  return loading === true ? (
    <Loading />
  ) : (
    <Container>
      <Content>
        <Form
          ref={formRefSinister}
          onSubmit={handleSubmitSinister}
          initialData={sinister}
        >
          <Input
            name="sinister_number"
            type="text"
            icon={IconDocument}
            placeholder="Número do processo"
            required
          />

          <GroupDate>
            <h3>Data:</h3>
            <DateInput
              name="sinister_date"
              selected={sinister_date}
              onChange={(date) => setSinister_date(date)}
            />
          </GroupDate>

          <Input
            name="sinister_type"
            type="text"
            icon={IconDocument}
            placeholder="Tipo"
            required
          />
          <Input
            name="sinister_event_type"
            type="text"
            icon={IconDocument}
            placeholder="Evento"
            required
          />
          <Input
            name="sinister_cause"
            type="text"
            icon={IconDocument}
            placeholder="Causa"
            required
          />
          <Input
            name="quantity_shipped"
            type="number"
            icon={IconPackage}
            placeholder="Quantidade embarcada"
          />
          <Input
            name="quantity_received"
            type="number"
            icon={IconPackage}
            placeholder="Quantidade recebida"
          />
          <Input
            name="broken_quantity"
            type="number"
            icon={IconPackage}
            placeholder="Quantidade danificada"
          />
          <Input
            name="product"
            type="text"
            icon={IconPackage}
            placeholder="Produto"
          />
          <Input
            name="package"
            type="text"
            icon={IconPackage}
            placeholder="Embalagem"
          />
          <Input
            name="shipping_value"
            type="number"
            icon={IconPrice}
            placeholder="Valor envio"
            inputProps={{ step: '0.01' }}
          />
          <Input
            name="damage_estimate"
            type="number"
            icon={IconPrice}
            placeholder="Prejuízo estimado"
            inputProps={{ step: '0.01' }}
          />
          <Input
            name="transport_type"
            type="text"
            icon={IconTruck}
            placeholder="Tipo de transporte"
          />
          <Input
            name="product_malfunctions"
            type="text"
            icon={IconPackage}
            placeholder="Produtos com avarias"
          />
          <Input
            name="comments"
            multiline
            rows={3}
            isTextAreaInput
            type="text"
            icon={IconDocument}
            placeholder="Comentários"
          />

          <GroupDate>
            <h3>Data acionamento:</h3>
            <DateInput
              timeIntervals={15}
              showTimeSelect
              dateFormat="dd/MM/yyyy,HH:mm aa"
              name="trigger_date"
              selected={trigger_date}
              onChange={(date) => setTrigger_date(date)}
            />
          </GroupDate>

          <GroupDate>
            <h3>Previsão de chegada:</h3>
            <DateInput
              timeIntervals={15}
              showTimeSelect
              dateFormat="dd/MM/yyyy,HH:mm aa"
              name="arrival_forecast"
              selected={arrival_forecast}
              onChange={(date) => setArrival_forecast(date)}
            />
          </GroupDate>
          <Button type="submit">Salvar</Button>
        </Form>
      </Content>
    </Container>
  );
}
