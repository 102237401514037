import * as Yup from 'yup';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { mask, unMask } from 'remask';
import { Form } from '@unform/web';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import coloricon from '../../styles/coloricon';
import api from '../../services/api';
import { Container, Content } from './styles';

export default function InsuredInput({ idSinister }) {
  const formRefInsured = useRef(null);
  const [loading, setLoading] = useState(true);
  const { IconUser, IconDocument, IconPhone, IconMail } = coloricon;
  const [insured, setInsured] = useState(null);
  const [insured_cpf_cnpj, setInsured_cpf_cnpj] = useState('');
  const [insured_phone, setInsured_phone] = useState('');

  const onChangeMaskedInsured_cpf_cnpj = useCallback((event) => {
    const originalValue = unMask(event.target.value);
    const masked = mask(originalValue, [
      '999.999.999-99',
      '99.999.999/9999-99',
    ]);
    setInsured_cpf_cnpj(masked);
  }, []);

  const onChangeMaskedInsured_phone = useCallback((event) => {
    const originalValue = unMask(event.target.value);
    const masked = mask(originalValue, ['(99) 9999-9999', '(99) 99999-9999']);
    setInsured_phone(masked);
  }, []);

  useEffect(() => {
    async function loaderItems() {
      try {
        const response = await api.get(`/sinisters/${idSinister}`);

        setInsured_cpf_cnpj(
          response.data?.insured && response.data?.insured.insured_cpf_cnpj
            ? response.data?.insured.insured_cpf_cnpj
            : ''
        );

        setInsured_phone(
          response.data?.insured && response.data?.insured.insured_phone
            ? response.data?.insured.insured_phone
            : ''
        );

        setInsured(response.data.insured);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(
          error?.response.data.error || 'Falha no carregamento das informações!'
        );
      }
    }
    loaderItems();
  }, [idSinister]);

  const handleSubmitInsured = useCallback(
    async (data) => {
      try {
        const schemaValidation = Yup.object().shape({
          insured_name: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O nome da corretora é obrigatória!'),
            otherwise: Yup.string(),
          }),

          insured_cpf_cnpj_formatted: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required('O CPF/CNPJ é obrigatório!')
              .min(14, 'O CPF/CNPJ precisa mínimo 14 dígitos!')
              .max(18, 'O CPF/CNPJ suporta máximo 18 dígitos!'),
            otherwise: Yup.string(),
          }),

          insured_contact: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O responsável é obrigatório!'),
            otherwise: Yup.string(),
          }),

          insured_phone_formatted: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string()
              .required('O telefone é obrigatório!')
              .min(14, 'O telefone precisa mínimo 10 dígitos!')
              .max(15, 'O telefone suporta máximo 11 dígitos!'),
            otherwise: Yup.string(),
          }),

          insured_mail: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().email().required('O email é obrigatório!'),
            otherwise: Yup.string(),
          }),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
        });

        const newData = {
          ...data,
          insured_cpf_cnpj: data.insured_cpf_cnpj_formatted
            ? data.insured_cpf_cnpj_formatted
            : null,
          insured_phone: data.insured_phone_formatted
            ? data.insured_phone_formatted
            : null,
        };

        await api.put(`/sinisters/${idSinister}`, { insuredReceived: newData });

        toast.info('Os dados foram atualizados com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            toast.info(
              `Falha no preenchimento do campo ${error.path}. Mensagem: ${error.message}`
            );
          });
        } else {
          toast.info(
            err?.response.data.error ||
              'Falha no processamento das informações!'
          );
        }
      }
    },
    [idSinister]
  );

  return loading === true ? (
    <Loading />
  ) : (
    <Container>
      <Content>
        <Form
          ref={formRefInsured}
          initialData={insured}
          onSubmit={handleSubmitInsured}
        >
          <Input
            name="insured_name"
            type="text"
            icon={IconUser}
            placeholder="Nome segurado"
          />
          <Input
            name="insured_cpf_cnpj_formatted"
            type="text"
            value={insured_cpf_cnpj}
            onChange={onChangeMaskedInsured_cpf_cnpj}
            icon={IconDocument}
            placeholder="CPF/CNPJ Segurado"
          />
          <Input
            name="insured_contact"
            type="text"
            icon={IconUser}
            placeholder="Responsável"
          />
          <Input
            name="insured_phone_formatted"
            value={insured_phone}
            onChange={onChangeMaskedInsured_phone}
            type="text"
            icon={IconPhone}
            placeholder="DDD + Telefone"
          />
          <Input
            name="insured_mail"
            type="email"
            icon={IconMail}
            placeholder="Email"
          />
          <Button type="submit">Salvar</Button>
        </Form>
      </Content>
    </Container>
  );
}
