import React from 'react';
import { Banner, BannerArea, Content } from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function ComoFunciona() {
  return (
    <>
      <Header />
      <Banner>
        <BannerArea>
          <Content>
            <h1>Quem somos</h1>
            <p>
              Fundada em 2022, sob o CNPJ 35.477.823/0001-97 e sede no bairro
              Eldorado em São Paulo - SP, a Inova nasceu para atender todo o
              território nacional no segmento de comissária de avarias de
              sinistros com foco no transporte multimodal de cargas.
            </p>
          </Content>
        </BannerArea>
      </Banner>

      <Footer />
    </>
  );
}
