import React from 'react';
import coloricon from '../../styles/coloricon';

const { background } = coloricon;

export default function DashUser() {
  return (
    <div style={{ backgroundColor: background, minHeight: '100%' }}>
      <h1>Seja bem vindo!</h1>
      <h2>Inova Comissária de Avarias</h2>
    </div>
  );
}
