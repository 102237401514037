import styled from 'styled-components';
import banner from '../../assets/banner.jpg';
import coloricon from '../../styles/coloricon';

const { secondaryColor } = coloricon;

const imageChoosed = banner;

export const Banner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(${imageChoosed}) no-repeat center;
  background-size: cover;
  height: calc(100vh - 80px - 100px);
  width: 100%;

  @media (max-width: 400px) {
    height: auto;
  }
`;

export const BannerArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: inherit;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  padding: 40px;

  h1 {
    color: ${secondaryColor};
  }

  p {
    line-height: 1.3;
    margin: 15px 0px;

    > svg {
      color: ${secondaryColor};
      font-size: 20px;
      margin-right: 10px;
    }
  }
`;
