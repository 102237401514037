import coloricon from '../../styles/coloricon';

const { IconHome, IconUser, IconMap, IconCalendar } = coloricon;

export const menuUser = [
  { text: 'Início', Icon: IconHome, path: '/dashUser' },
  { text: 'Perfil', Icon: IconUser, path: '/profileUser' },
  { text: 'Sinistros', Icon: IconMap, path: '/sinisterManager' },
  { text: 'Relatórios', Icon: IconCalendar, path: '/reports' },
];
