import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';
import clearLocalStorage from './clearLocalStorage';

export default function UserContext() {
  const [dataUser, setDataUser] = useState(() => {
    const storage = localStorage.getItem(
      `@${process.env.REACT_APP_NAME}:dataUser`
    );

    if (storage) {
      const storageParsed = JSON.parse(storage);
      api.defaults.headers.Authorization = `Bearer ${storageParsed.token}`;
      return {
        token: storageParsed.token,
        user: storageParsed.user,
      };
    }
    return { token: null, user: null };
  });

  const handleLoginUser = useCallback(async (email, password) => {
    try {
      const response = await api.post('/sessionUser', { email, password });
      const { token, user } = response.data;
      const data = { token, user };
      clearLocalStorage();

      localStorage.setItem(
        `@${process.env.REACT_APP_NAME}:dataUser`,
        JSON.stringify(data)
      );

      setDataUser({ token, user });
      api.defaults.headers.Authorization = `Bearer ${token}`;
      return '/dashUser';
    } catch (err) {
      toast.info(
        err?.response.data.error || 'Falha no carregamento das informações!'
      );
      return null;
    }
  }, []);

  const handleLogoutUser = useCallback(async () => {
    clearLocalStorage();
    setDataUser({ token: null, user: null });
    api.defaults.headers.Authorization = undefined;
  }, []);

  return {
    dataUser,
    setDataUser,
    handleLoginUser,
    handleLogoutUser,
  };
}
