import styled from 'styled-components';
import banner from '../../assets/banner.jpg';

const imageChoosed = banner;

export const Banner = styled.div`
  width: 100%;
  min-height: calc(100vh - 100px - 80px);
  display: flex;
  flex-direction: column;
  background-image: url(${imageChoosed});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const BannerArea = styled.div`
  display: flex;
  width: 100%;
  min-height: calc(100vh - 70px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: inherit;
  min-height: calc(100vh - 100px - 80px);
  background-color: rgba(0, 0, 0, 0.8);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 10px;

  @media (max-width: 768px) {
    font-size: 15px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }

  > form {
    width: 80%;
    display: flex;
    flex-direction: column;
  }

  > h1 {
    text-align: center;
  }
`;
