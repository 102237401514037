import React from 'react';
import { Footer, FooterCopy, FooterInfo, GroupRow } from './styles';

import coloricon from '../../styles/coloricon';

export default function FooterHome() {
  const yearCurrent = new Date().getFullYear();
  const { IconInstagram, IconFacebook, IconWhatsApp } = coloricon;

  return (
    <Footer>
      <GroupRow>
        <FooterInfo>
          <a
            href="https://www.instagram.com/instagram"
            target="blank"
            aria-label="instagram"
          >
            <IconInstagram size={25} />
          </a>

          <a
            href="https://www.facebook.com/instagram"
            target="blank"
            aria-label="facebook"
          >
            <IconFacebook size={25} />
          </a>
          <a
            href="https://wa.me/5511963431215?text=Quero orçamento para vistoria!"
            target="blank"
            aria-label="whatsapp"
          >
            <IconWhatsApp size={30} />
          </a>
        </FooterInfo>
      </GroupRow>

      <FooterCopy>
        2022 | {yearCurrent} - Inova Comissária de Avarias | Todos os direitos
        reservados.
      </FooterCopy>
    </Footer>
  );
}
