import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { secondaryColor, fontColor } = coloricon;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin-bottom: 35px;

  @media (max-width: 768px) {
    font-size: 15px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }

  > h1 {
    margin: 15px 0px;
  }

  > button {
    width: 100%;
    margin-bottom: 15px;
  }

  > form {
    width: 70%;
  }

  tr td {
    svg {
      cursor: pointer;
      font-size: 18px;
      color: ${secondaryColor};
      transition: all 0.3s ease-in-out;

      :hover {
        color: ${fontColor};
      }
    }
  }
`;

export const GroupButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  @media (max-width: 550px) {
    flex-direction: column;
    height: auto;
  }

  > button {
    width: 100%;
    margin: 0px 5px;

    @media (max-width: 550px) {
      margin: 10px 0;
    }
  }
`;
