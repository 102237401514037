import styled from 'styled-components';
import coloricon from '../../styles/coloricon';

const { secondaryColor, secondaryColorHover, fontColor } = coloricon;

export const Container = styled.button`
  font-weight: bold;
  margin-top: 10px;
  height: 45px;
  border: 0;
  border-radius: 5px;
  background-color: ${secondaryColor};
  color: ${fontColor};
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;

  &:hover {
    background-color: ${secondaryColorHover};
  }
`;
