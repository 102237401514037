import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Backdrop, CircularProgress } from '@material-ui/core';
import ExportCSV from '../../utils/ExportCSV';
import api from '../../services/api';
import { Container, Content } from './styles';

export default function ServiceManager() {
  const [loading, setLoading] = useState(true);
  const [sinisters, setSinisters] = useState([]);

  useEffect(() => {
    async function loaderSinisters() {
      try {
        const response = await api.get('/sinisters');
        setSinisters(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(error.response.data.error);
      }
    }
    loaderSinisters();
  }, []);

  return loading === true ? (
    <Backdrop open>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Container>
      <Content>
        <h1>Relatórios</h1>

        <p>Informações em Excel dos sinistros cadastrados em banco de dados</p>

        {sinisters && sinisters.length === 0 && (
          <p>Não existem registros para exibir.</p>
        )}

        {sinisters && sinisters.length > 0 && (
          <ExportCSV fileName="controle_de_vistorias" csvData={sinisters} />
        )}
      </Content>
    </Container>
  );
}
