import * as Yup from 'yup';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import Input from '../../components/Input';
import DateInput from '../../components/DatePickerInput';
import RadioInput from '../../components/RadioInput';
import Loading from '../../components/Loading';
import Button from '../../components/Button';
import { GroupDate, Container, Content } from './styles';
import api from '../../services/api';
import coloricon from '../../styles/coloricon';

export default function DetailInput({ idSinister }) {
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState(null);
  const formRefDetails = useRef(null);
  const { IconDocument } = coloricon;
  const [invoice_issue_date, setInvoice_issue_date] = useState(new Date());
  const [manifest_issue_date, setManifest_issue_date] = useState(new Date());
  const [knowledge_issue_date, setKnowledge_issue_date] = useState(new Date());

  const [escortOptions, setEscortOptions] = useState([
    { id: 1, label: 'Sim', value: 'sim', selected: false },
    { id: 2, label: 'Não', value: 'nao', selected: false },
  ]);

  const [tele_monitoringOptions, setTele_monitoringOptions] = useState([
    { id: 1, label: 'Sim', value: 'sim', selected: false },
    { id: 2, label: 'Não', value: 'nao', selected: false },
  ]);

  const [
    insurer_authorizes_removalOptions,
    setInsurer_authorizes_removalOptions,
  ] = useState([
    { id: 1, label: 'Sim', value: 'sim', selected: false },
    { id: 2, label: 'Não', value: 'nao', selected: false },
  ]);

  const [the_police_where_thereOptions, setThe_police_where_thereOptions] =
    useState([
      { id: 1, label: 'Sim', value: 'sim', selected: false },
      { id: 2, label: 'Não', value: 'nao', selected: false },
    ]);

  const [refundOptions, setRefundOptions] = useState([
    { id: 1, label: 'Sim', value: 'sim', selected: false },
    { id: 2, label: 'Não', value: 'nao', selected: false },
  ]);

  const [trackingOptions, setTrackingOptions] = useState([
    { id: 1, label: 'Sim', value: 'sim', selected: false },
    { id: 2, label: 'Não', value: 'nao', selected: false },
  ]);

  const [vehicle_foundOptions, setVehicle_foundOptions] = useState([
    { id: 1, label: 'Sim', value: 'sim', selected: false },
    { id: 2, label: 'Não', value: 'nao', selected: false },
  ]);

  const [preserved_productsOptions, setPreserved_productsOptions] = useState([
    { id: 1, label: 'Sim', value: 'sim', selected: false },
    { id: 2, label: 'Não', value: 'nao', selected: false },
  ]);

  const [has_salvageOptions, setHas_salvageOptions] = useState([
    { id: 1, label: 'Sim', value: 'sim', selected: false },
    { id: 2, label: 'Não', value: 'nao', selected: false },
  ]);

  useEffect(() => {
    async function loaderItems() {
      try {
        const response = await api.get(`/sinisters/${idSinister}`);

        setDetails(response.data.detail);

        setInvoice_issue_date(
          response.data.detail.invoice_issue_date
            ? parseISO(response.data.detail.invoice_issue_date)
            : new Date()
        );

        setManifest_issue_date(
          response.data.detail.manifest_issue_date
            ? parseISO(response.data.detail.manifest_issue_date)
            : new Date()
        );

        setKnowledge_issue_date(
          response.data.detail.knowledge_issue_date
            ? parseISO(response.data.detail.knowledge_issue_date)
            : new Date()
        );

        // escort
        if (response.data.detail.escort) {
          const index = escortOptions.findIndex(
            (item) => item.value === response.data.detail.escort
          );
          if (index > -1) {
            escortOptions[index].selected === true;
            setEscortOptions(escortOptions);
          }
        }

        // tele_monitoring
        if (response.data.detail.tele_monitoring) {
          const index = tele_monitoringOptions.findIndex(
            (item) => item.value === response.data.detail.tele_monitoring
          );
          if (index > -1) {
            tele_monitoringOptions[index].selected === true;
            setTele_monitoringOptions(tele_monitoringOptions);
          }
        }

        // insurer
        if (response.data.detail.insurer_authorizes_removal) {
          const index = insurer_authorizes_removalOptions.findIndex(
            (item) =>
              item.value === response.data.detail.insurer_authorizes_removal
          );
          if (index > -1) {
            insurer_authorizes_removalOptions[index].selected === true;
            setInsurer_authorizes_removalOptions(
              insurer_authorizes_removalOptions
            );
          }
        }

        // police
        if (response.data.detail.the_police_where_there) {
          const index = the_police_where_thereOptions.findIndex(
            (item) => item.value === response.data.detail.the_police_where_there
          );
          if (index > -1) {
            the_police_where_thereOptions[index].selected === true;
            setThe_police_where_thereOptions(the_police_where_thereOptions);
          }
        }

        // refund
        if (response.data.detail.refund) {
          const index = refundOptions.findIndex(
            (item) => item.value === response.data.detail.refund
          );
          if (index > -1) {
            refundOptions[index].selected === true;
            setRefundOptions(refundOptions);
          }
        }

        // tracking
        if (response.data.detail.tracking) {
          const index = trackingOptions.findIndex(
            (item) => item.value === response.data.detail.tracking
          );
          if (index > -1) {
            trackingOptions[index].selected === true;
            setTrackingOptions(trackingOptions);
          }
        }

        // preserved products
        if (response.data.detail.preserved_products) {
          const index = preserved_productsOptions.findIndex(
            (item) => item.value === response.data.detail.preserved_products
          );
          if (index > -1) {
            preserved_productsOptions[index].selected === true;
            setPreserved_productsOptions(preserved_productsOptions);
          }
        }

        // vehicle_foundOptions
        if (response.data.detail.vehicle_found) {
          const index = vehicle_foundOptions.findIndex(
            (item) => item.value === response.data.detail.vehicle_found
          );
          if (index > -1) {
            vehicle_foundOptions[index].selected === true;
            setVehicle_foundOptions(vehicle_foundOptions);
          }
        }

        // has_salvageOptions
        if (response.data.detail.has_salvage) {
          const index = has_salvageOptions.findIndex(
            (item) => item.value === response.data.detail.has_salvageOptions
          );
          if (index > -1) {
            has_salvageOptions[index].selected === true;
            setHas_salvageOptions(has_salvageOptions);
          }
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.info(
          error?.response.data.error || 'Falha no carregamento das informações!'
        );
      }
    }
    loaderItems();
  }, [
    escortOptions,
    has_salvageOptions,
    idSinister,
    insurer_authorizes_removalOptions,
    preserved_productsOptions,
    refundOptions,
    tele_monitoringOptions,
    the_police_where_thereOptions,
    trackingOptions,
    vehicle_foundOptions,
  ]);

  const handleSubmitDetails = useCallback(
    async (data) => {
      try {
        const schemaValidation = Yup.object().shape({
          contract_number: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O número da apólice é obrigatório!'),
            otherwise: Yup.string(),
          }),

          invoice_number: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required(
              'O número da nota fiscal é obrigatória!'
            ),
            otherwise: Yup.string(),
          }),

          invoice_issue_date_formatted: Yup.date().required(
            'A data é obrigatória!'
          ),

          manifest_number: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('O número do manifesto é obrigatório!'),
            otherwise: Yup.string(),
          }),

          manifest_issue_date_formatted: Yup.date().required(
            'A data do manifesto é obrigatória!'
          ),

          knowledge_number: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required(
              'O número do conhecimento é obrigatório!'
            ),
            otherwise: Yup.string(),
          }),

          knowledge_issue_date_formatted: Yup.date().required(
            'A data do conhecimento é obrigatória!'
          ),

          escort: Yup.boolean().when({
            is: (val) => val && val.length > 0,
            then: Yup.boolean().required('O status de escolta é obrigatória!'),
            otherwise: Yup.boolean().nullable(true),
          }),

          tele_monitoring: Yup.boolean().when({
            is: (val) => val && val.length > 0,
            then: Yup.boolean().required(
              'O status de telemonitoramento é obrigatório!'
            ),
            otherwise: Yup.boolean().nullable(true),
          }),

          insurer_authorizes_removal: Yup.boolean().when({
            is: (val) => val && val.length > 0,
            then: Yup.boolean().required('O status de remoção é obrigatório!'),
            otherwise: Yup.boolean().nullable(true),
          }),

          the_police_where_there: Yup.boolean().when({
            is: (val) => val && val.length > 0,
            then: Yup.boolean().required('O status de polícia é obrigatório!'),
            otherwise: Yup.boolean().nullable(true),
          }),

          refund: Yup.boolean().when({
            is: (val) => val && val.length > 0,
            then: Yup.boolean().required(
              'O status de reembolso é obrigatório!'
            ),
            otherwise: Yup.boolean().nullable(true),
          }),

          incidente_report: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required(
              'O boletim de ocorrência é obrigatório!'
            ),
            otherwise: Yup.string(),
          }),

          tracking: Yup.boolean().when({
            is: (val) => val && val.length > 0,
            then: Yup.boolean().required(
              'O status de monitoramento é obrigatório!'
            ),
            otherwise: Yup.boolean().nullable(true),
          }),

          vehicle_found: Yup.boolean().when({
            is: (val) => val && val.length > 0,
            then: Yup.boolean().required(
              'O status de veículo encontrado é obrigatório!'
            ),
            otherwise: Yup.boolean().nullable(true),
          }),

          preserved_products: Yup.boolean().when({
            is: (val) => val && val.length > 0,
            then: Yup.boolean().required(
              'O status de produtos preservados é obrigatório!'
            ),
            otherwise: Yup.boolean().nullable(true),
          }),

          has_salvage: Yup.boolean().when({
            is: (val) => val && val.length > 0,
            then: Yup.boolean().required(
              'O status de produtos salvados é obrigatório!'
            ),
            otherwise: Yup.boolean().nullable(true),
          }),

          specification: Yup.string().when({
            is: (val) => val && val.length > 0,
            then: Yup.string().required('As especificações são obrigatórias!'),
            otherwise: Yup.string(),
          }),
        });

        const dataForSchema = {
          ...data,
        };

        const dataFormatted = {
          ...data,

          invoice_issue_date: data.invoice_issue_date_formatted
            ? data.invoice_issue_date_formatted
            : null,

          manifest_issue_date: data.manifest_issue_date_formatted
            ? data.manifest_issue_date_formatted
            : null,

          knowledge_issue_date: data.knowledge_issue_date_formatted
            ? data.knowledge_issue_date_formatted
            : null,
        };
        await schemaValidation.validate(dataForSchema, {
          abortEarly: false,
        });

        await api.put(`/sinisters/${idSinister}`, {
          detailReceived: dataFormatted,
        });

        toast.info('Os detalhes foram atualizados com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            toast.info(
              `Falha no preenchimento do campo ${error.path}. Mensagem: ${error.message}`
            );
          });
        } else {
          toast.info(
            err?.response.data.error ||
              'Falha no processamento das informações!'
          );
        }
      }
    },
    [idSinister]
  );

  return loading === true ? (
    <Loading />
  ) : (
    <Container>
      <Content>
        <Form
          ref={formRefDetails}
          initialData={details}
          onSubmit={handleSubmitDetails}
        >
          <Input
            type="text"
            name="contract_number"
            placeholder="Número da apólice"
            icon={IconDocument}
          />

          <Input
            type="text"
            name="invoice_number"
            placeholder="Nota Fiscal"
            icon={IconDocument}
          />

          <GroupDate>
            <h3>Data emissão NF:</h3>
            <DateInput
              name="invoice_issue_date_formatted"
              selected={invoice_issue_date}
              onChange={(date) => setInvoice_issue_date(date)}
            />
          </GroupDate>

          <Input
            type="text"
            name="manifest_number"
            placeholder="Número manifesto"
            icon={IconDocument}
          />

          <GroupDate>
            <h3>Data emissão manifesto:</h3>
            <DateInput
              name="manifest_issue_date_formatted"
              selected={manifest_issue_date}
              onChange={(date) => setManifest_issue_date(date)}
            />
          </GroupDate>

          <Input
            type="text"
            name="knowledge_number"
            placeholder="Número conhecimento"
            icon={IconDocument}
          />

          <GroupDate>
            <h3>Data emissão conhecimento</h3>
            <DateInput
              name="knowledge_issue_date_formatted"
              selected={knowledge_issue_date}
              onChange={(date) => setKnowledge_issue_date(date)}
            />
          </GroupDate>

          <RadioInput name="escort" label="Escolta" options={escortOptions} />

          <RadioInput
            name="tele_monitoring"
            label="Telemonitoramento:"
            options={tele_monitoringOptions}
          />

          <RadioInput
            name="insurer_authorizes_removal"
            label="Autorizou a remoção:"
            options={insurer_authorizes_removalOptions}
          />

          <RadioInput
            name="the_police_where_there"
            label="Polícia esteve no local"
            options={the_police_where_thereOptions}
          />

          <RadioInput name="refund" label="Reembolso" options={refundOptions} />

          <Input
            type="text"
            name="incidente_report"
            placeholder="Boletim de ocorrência"
            icon={IconDocument}
          />

          <RadioInput
            name="tracking"
            label="Monitoramento"
            options={trackingOptions}
          />

          <RadioInput
            name="vehicle_found"
            label="Veículo encontrado"
            options={vehicle_foundOptions}
          />

          <RadioInput
            name="preserved_products"
            label="Produtos preservados"
            options={preserved_productsOptions}
          />

          <RadioInput
            name="has_salvage"
            label="Produtos salvados"
            options={has_salvageOptions}
          />

          <Input
            type="text"
            name="specification"
            placeholder="Especificações"
            icon={IconDocument}
          />
          <Button type="submit">Salvar</Button>
        </Form>
      </Content>
    </Container>
  );
}
